import React from "react";
import Title from "../../../components/Title/Title";


const CallforPapers2025page = () => {
  return (
    <div>
      <Title>Call for Papers</Title>

     <p>We invite submissions to the 4th Conference on Causal Learning and Reasoning (CLeaR), and welcome paper submissions that describe new theory, methodology, and/or applications relevant to any aspect of causal learning and reasoning in the fields of artificial intelligence and statistics. Accepted papers will be published in the Proceedings of Machine Learning Research (PMLR).
</p>
		<h5>Key dates</h5>
    The planned dates are as follows:
    <ul>
    <li> Paper submission deadline: <s>Nov 2, 2024 11:59pm</s> Nov 4, 2024 11:59pm (Anywhere on Earth, AoE)</li>
    <li> Reviews released: Dec 13, 2024</li>
    <li> Author rebuttals due: Dec 20, 2024 11:59pm (AoE)</li>
    <li> Final decisions: Jan 27, 2025</li>
    <li> Camera-ready deadline: Mar 9, 2025 11:59pm (AoE)</li>
    <li> Conference dates: May 7 (Wed) - 9 (Fri), 2025.</li>
    </ul>
    <p>Submit at <a href="https://openreview.net/group?id=cclear.cc/CLeaR/2025/Conference">https://openreview.net/group?id=cclear.cc/CLeaR/2025/Conference</a>.</p>

  <h5>Summary</h5>
  <p> Causality is a fundamental notion in science and engineering. In the past few decades, some of the most influential developments in the study of causal discovery, causal inference, and the causal treatment of machine learning have resulted from cross-disciplinary efforts. In particular, a number of machine learning and statistical analysis techniques have been developed to tackle classical causal discovery and inference problems. On the other hand, the causal view has been shown to facilitate formulating, understanding, and tackling a broad range of problems, including domain generalization, robustness, trustworthiness, and fairness across machine learning, reinforcement learning, and statistics.
  </p>
  <p> We invite papers that describe new theory, methodology and/or applications relevant to any aspect of causal learning and reasoning in the fields of artificial intelligence and statistics. Submitted papers will be evaluated based on their novelty, technical quality, and potential impact. Experimental methods and results are expected to be reproducible, and authors are strongly encouraged to make code and data available. We also encourage submissions of proof-of-concept research that puts forward novel ideas and demonstrates potential for addressing problems at the intersection of causality and machine learning.
Paper Submission
The proceedings track is the standard CLeaR paper submission track. Papers will be selected via a rigorous double-blind peer-review process. All accepted papers will be presented at the Conference as contributed talks or as posters and will be published in the Proceedings.
</p>

<p> Topics of submission may include, but are not limited to:</p>
<ul>
<li>Foundational theories of causation </li>
<li>Causal effect identification and estimation </li>
<li> Causal discovery in complex environments</li>
<li> Efficient causal discovery in large-scale datasets</li>
<li> Causal representation learning</li>
<li> Machine learning (including reinforcement learning) building on causal principles </li>
<li> Unsupervised and semi-supervised deep learning connected to causality</li>
<li> Causal generative models for machine learning</li>
<li> Machine learning and statistical methods for heterogeneous data sources</li>
<li> Causality-empowered foundation models</li>
<li> Causally rooted methods for fairness, accountability, transparency, explainability, trustworthiness, and recourse </li>
<li> Benchmark for causal discovery and causal reasoning</li>
<li> Applications of any of the above to real-world problems</li>
</ul>

  <h5>Physical Attendance</h5>
  <p>CLeaR 2025 is being planned as an in-person conference with hybrid elements accommodating online presentations when physical attendance is not possible. </p>

  <h5>Formatting and Supplementary Material</h5>
  <p>Submissions are limited to 12 single-column PMLR-formatted pages, plus unlimited additional pages for references and appendices. Authors of accepted papers will have the option of opting out of the proceedings in favor of a 1-page extended abstract, which will point to an open access archival version of the full paper reviewed for CLeaR. You can also submit a single file of additional supplementary material separately, which may be either a pdf file (containing  proof details, for instance) or a zip file that can include multiple files of all formats (such as code or videos). Note that reviewers are under no obligation to examine the supplementary material.</p>

  <p>Please format the paper using the <a href="https://drive.google.com/drive/folders/1KTPiVeylR1_8o42kI8E2GbF-6ThMV6Zq" download>official LaTeX style files</a>. We do not support submission in formats other than LaTeX. Please do not modify the layout given by the style file.</p>


  <h5>Anonymization Requirements</h5>
  <p>The CLeaR review process is double-blind: reviewers and authors will both stay anonymous to each other during the review process. We use OpenReview to host papers; however, public discussions are not allowed during the review process. The review comments are only visible to program chairs, area chairs, and reviewers with submitted review comments. Papers will be desk-rejected if they contain any information that can violate the double-blind reviewing policy, such as the author names or their affiliations, acknowledgements, or links that can infer any author’s identity or institution. Self-citations are allowed as long as anonymity is preserved. It is up to the author’s discretion how best to preserve anonymity when including self-citations. Possibilities include: leaving out a self-citation, including it but replacing the citation text with “removed for anonymous submission,” or leaving the citation as-is. We recommend leaving in a moderate number of self-citations for published or otherwise well-known work. </p>

  <p>Revisions are allowed in the submission system until the paper submission deadline. Changes will not be allowed afterwards.</p>

  <p>We strongly discourage advertising the preprint on social media or in the press while under submission to CLeaR. Preprints must not be explicitly identified as an CLeaR submission at any time during the review period (i.e., from the abstract submission deadline until the notification of the accept/reject decision).</p>

  <h5>Dual Submissions</h5>
  <p>CLeaR does not allow double submissions. Namely, submissions should not have been previously published in or submitted to a journal or the proceedings of another conference at any point during the CLeaR review process. Submissions to workshops or other non-archival venues (without a proceedings) will not be considered as dual submissions. Submissions as extended abstracts with 5 pages or less will not be considered a concurrent submission either. Authors may submit anonymized work to CLeaR that is already available as a preprint (e.g., on arXiv) without citing it. Tech reports (including reports on sites such as arXiv) do not count as prior publication. It is acceptable to have a substantially extended version of the submitted paper under consideration simultaneously for journal publication, so long as the journal version’s planned publication date is after our publication (July 15, 2025, tentatively), and it does not violate the journal's policy, the journal submission does not interfere with CLeaR right to publish the paper, and the situation is clearly described at the time of CLeaR submission. Please describe the situation in the appropriate box on the submission page (and do not include author information in the submission itself, to avoid accidental unblinding). Authors are also allowed to give talks to restricted audiences on the work(s) submitted to CLeaR during the review.</p>

  <p>All accepted papers will be presented at the Conference either as contributed talks or as posters, and will be published in the CLeaR Conference Proceedings in the Journal of Machine Learning Research Workshop and Conference Proceedings series. Papers for talks and posters will be treated equally in publication.</p>

  <h5>Confidentiality</h5>
  <p>The reviewers and area-chairs will have access to papers and supplementary materials that are assigned to them. </p>

  <p>The program chairs and workflow chairs will have access to all the papers. Everyone having access to papers and supplementary materials will be instructed to keep them confidential during the review process and delete them after the final decisions.</p>

  <p>Reviews will be visible to area chairs, program chairs, and workflow chairs throughout the process. At any stage of the process, author names will not be known to the reviewers or area chairs, but only visible to program chairs. Reviewer names are visible to the area chair (and program chairs), but the reviewers will not know names of other reviewers.</p>

  <p>Mathias Drton & Biwei Huang  <br />
  CLeaR 2025 Program Chairs</p>

  <p>Negar Kiyavash & Jin Tian  <br />
  CLeaR 2025 General Chairs</p>


    </div>
  )
}

export default CallforPapers2025page
