import React from "react";
import Title from "../../../components/Title/Title";

const FullAgenda2024page = () => {
  return (
    <div>
      <Title>Full Agenda</Title>
      <h5><font color="darkcyan">Day 1 (April 1, Monday)</font></h5>
      <table>
      <tr><b>9:00-10:30 AM Arrival + registration + breakfast </b>
      </tr>
      <tr><b>10:30-11:00 AM Welcome</b>
      </tr>
      <tr><b>11:00-12:00 PM Keynote by Eric Tchetgen Tchetgen</b>
      <ul>
      <li>Introducing the Forster-Warmuth Nonparametric Counterfactual Regression</li>
      </ul>
      </tr>
      <tr><b>12:00-14:00 PM Lunch break</b>
      </tr>
      <tr><b>14:00-15:00 PM Oral I and Benchmark spotlights</b></tr>
      <ul>
      <li> Oral I.1. (15 mins) Best Paper (TBA)</li>
      <li> Oral I.2. (15 mins) Extracting the Multiscale Causal Backbone of Brain Dynamics</li>
      <li> Oral I.3. (15 mins) Bicycle: Intervention-Based Causal Discovery with Cycles</li>
      <li> Poster S.1. (5 mins) CausalAssembly: Generating Realistic Production Data for Benchmarking Causal Discovery</li>
      <li> Poster S.2. (5 mins) The PetShop Dataset — Finding Causes of Performance Issues across Microservices</li>
      <li> Poster S.3. (5 mins) Causal discovery in a complex industrial system: A time series benchmark</li>
      </ul>
      <tr><b>15:00-15:30 PM Coffee break</b>
      </tr>
      <tr><b>15:30-17:00 PM Poster I</b></tr>
      <ul>
      <li>Poster I.1. Structure Learning with Continuous Optimization: A Sober Look and Beyond</li>
      <li>Poster I.2. A causality-inspired plus-minus model for player evaluation in team sports</li>
      <li>Poster I.3. Inference of nonlinear causal effects with GWAS summary data</li>
      <li>Poster I.4. Implicit and Explicit Policy Constraints for Offline Reinforcement Learning</li>
      <li>Poster I.5. Estimating the Causal Effect of Early ArXiving on Paper Acceptance</li>
      <li>Poster I.6. Bootstrap aggregation and confidence measures to improve time series causal discovery</li>
      <li>Poster I.7. Extracting the Multiscale Causal Backbone of Brain Dynamics</li>
      <li>Poster I.8. The PetShop Dataset — Finding Causes of Performance Issues across Microservices</li>
      <li>Poster I.9. Evaluating and Correcting Performative Effects of Decision Support Systems via Causal Domain Shift</li>
      <li>Poster I.10. Causal Discovery Under Local Privacy</li>
      <li>Poster I.11. Causal Layering via Conditional Entropy</li>
      <li>Poster I.12. Causal Discovery with Mixed Linear and Nonlinear Additive Noise Models: A Scalable Approach</li>
      <li>Poster I.13. Causal discovery in a complex industrial system: A time series benchmark</li>
      <li>Poster I.14. Bicycle: Intervention-Based Causal Discovery with Cycles</li>
      <li>Poster I.15. CausalAssembly: Generating Realistic Production Data for Benchmarking Causal Discovery</li>
      </ul>
      <tr><b>17:00-18:00 PM Keynote by Bernhard Schölkopf</b>
      <ul>
      <li>Learning Causal Representations</li>
      </ul>
      </tr>
      </table>
      <br />
      <h5><font color="darkcyan">Day 2 (April 2, Tuesday)</font></h5>
      <table>
      <tr><b>9:00-9:30 AM Arrival + breakfast </b>
      </tr>
      <tr><b>9:30-10:00 AM Oral II</b></tr>
      <ul>
      <li> Oral II.1. Cautionary Tales on Synthetic Controls in Survival Analyses</li>
      <li> Oral II.2. Fundamental Properties of Causal Entropy and Information Gain</li>
      </ul>
      <tr><b>10:00-10:30 AM Coffee break</b>
      </tr>
      <tr><b>10:30-12:00 PM Poster II</b></tr>
      <ul>
      <li>Poster II.1. Sequential Deconfounding for Causal Inference with Unobserved Confounders</li>
      <li>Poster II.2. Cautionary Tales on Synthetic Controls in Survival Analyses</li>
      <li>Poster II.3. Fundamental Properties of Causal Entropy and Information Gain</li>
      <li>Poster II.4. Causality of Functional Longitudinal Data</li>
      <li>Poster II.5. Causal Matching using Random Hyperplane Tessellations</li>
      <li>Poster II.6. Lifted Causal Inference in Relational Domains</li>
      <li>Poster II.7. Scalable Counterfactual Distribution Estimation in Multivariate Causal Models</li>
      <li>Poster II.8. Pragmatic Fairness: Developing Policies with Outcome Disparity Control</li>
      <li>Poster II.9. Dual Likelihood for Causal Inference under Structure Uncertainty</li>
      <li>Poster II.10. On the Identifiability of Quantized Factors</li>
      <li>Poster II.11. Meaningful Causal Aggregation and Paradoxical Confounding</li>
      <li>Poster II.12. Semiparametric Efficient Inference in Adaptive Experiments</li>
      <li>Poster II.13. Causal Imputation for Counterfactual SCMs: Bridging Graphs and Latent Factor Models</li>
      <li>Poster II.14. Causal State Distillation for Explainable Reinforcement Learning</li>
      <li>Poster II.15. Expediting Reinforcement Learning by Incorporating Knowledge About Temporal Causality in the Environment</li>
      </ul>
      <tr><b>12:00-13:30 PM Lunch break</b>
      </tr>
      <tr><b>13:30-14:30 PM Keynote by Tania Lombrozo</b>
      <ul>
      <li>Simplicity and Stability in Human Causal Reasoning</li>
      </ul>
      </tr>
      <tr><b>14:30-18:00 PM Social activity</b>
      </tr>
      <tr><b>18:00 PM Dinner</b>
      </tr>
      <tr><b>19:30-20:00 PM Keynote by Judea Pearl</b>
      </tr>
      </table>
      <br />
      <h5><font color="darkcyan">Day 3 (April 3, Wednesday)</font></h5>
      <table>
      <tr><b>9:00-9:30 AM Arrival + breakfast </b>
      </tr>
      <tr><b>9:30-10:30 AM Keynote by Elizabeth Tipton</b>
      <ul>
      <li>Integrating diverse evidence: From science to policymaking</li>
      </ul>
      </tr>
      <tr><b>10:30-11:00 AM Townhall discussion</b>
      </tr>
      <tr><b>11:00-12:00 PM Oral III</b></tr>
      <ul>
      <li> Oral III.1. Pragmatic Fairness: Developing Policies with Outcome Disparity Control</li>
      <li> Oral III.2. Dual Likelihood for Causal Inference under Structure Uncertainty</li>
      <li> Oral III.3. Causal State Distillation for Explainable Reinforcement Learning</li>
      <li> Oral III.4. Finding Alignments Between Interpretable Causal Variables and Distributed Neural Representations</li>
      </ul>
      <tr><b>12:00-14:00 PM Lunch break</b>
      </tr>
      <tr><b>14:00-15:00 PM Oral IV</b></tr>
      <ul>
      <li> Oral IV.1. Towards the Reusability and Compositionality of Causal Representations</li>
      <li> Oral IV.2. An Interventional Perspective on Identifiability in Gaussian LTI Systems with Independent Component Analysis</li>
      <li> Oral IV.3. Ensembled Prediction Intervals for Causal Outcomes Under Hidden Confounding</li>
      </ul>
      <tr><b>15:00-15:30 PM Coffee Break</b>
      </tr>
      <tr><b>15:30-17:00 PM Poster III</b></tr>
      <ul>
      <li>Poster III.1. Towards the Identifiability of Comparative Deep Generative Models</li>
      <li>Poster III.2. Robustness of Algorithms for Causal Structure Learning to Hyperparameter Choice</li>
      <li>Poster III.3. Finding Alignments Between Interpretable Causal Variables and Distributed Neural Representations</li>
      <li>Poster III.4. DiConStruct: Causal Concept-based Explanations through Black-Box Distillation</li>
      <li>Poster III.5. Monitoring the performance of machine learning algorithms that induce feedback loops: what is the causal estimand?</li>
      <li>Poster III.6. Towards the Reusability and Compositionality of Causal Representations</li>
      <li>Poster III.7. Hyperparameter Tuning for Causal Inference with Double Machine Learning: A Simulation Study</li>
      <li>Poster III.8. Identifying Linearly-Mixed Causal Representations from Multi-Node Interventions</li>
      <li>Poster III.9. On the Lasso for Graphical Continuous Lyapunov Models</li>
      <li>Poster III.10. Causal Optimal Transport of Abstractions</li>
      <li>Poster III.11. An Interventional Perspective on Identifiability in Gaussian LTI Systems with Independent Component Analysis</li>
      <li>Poster III.12. Ensembled Prediction Intervals for Causal Outcomes Under Hidden Confounding</li>
      <li>Poster III.13. Low-Rank Approximation of Structural Redundancy for Self-Supervised Learning</li>
      <li>Poster III.14. Confounded Budgeted Causal Bandits</li>
      <li>Poster III.15. On the Impact of Neighbourhood Sampling to Satisfy Sufficiency and Necessity Criteria in Explainable AI</li>
      </ul>
      <tr><b>17:00-17:30 PM Wrap-up</b>
      </tr>
      </table>
      <br />
    </div>
  )
}

export default FullAgenda2024page
