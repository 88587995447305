import React from "react";
import Title from "../../../components/Title/Title";

const CodeConduct2023page = () => {
  return (
    <div>
      <Title>Code of Conduct</Title>
     <h5>Purpose</h5>
     <p>At CLeaR 2023, our goal is to create a community and environment that recognizes and respects the intrinsic value of everyone, which is essential for the open exchange of ideas, freedom of thought and expression, and respectful scientific debate at the conference.</p>
    <h5>Who</h5>
     <p>All participants, including but not limited to organizers, reviewers, speakers, sponsors, and volunteers at our Conference and Conference-sponsored social events are required to agree with this Code of Conduct both during an event and on official communication channels, including social media. In particular, sponsors should not use sexual, racial, or other offensive images, events, or other materials. This code applies to both official sponsors and any organization that uses the conference name as a brand as part of its activities during or around the conference.</p>
    <h5>Policy</h5>
     <p>CLeaR is committed to providing all participants with an experience free from harassment, bullying, discrimination and retaliation. This includes offensive comments related to age, gender, gender identity and expression, race, sexual orientation, physical or intellectual disability, physical appearance, body type, ethnicity, religion, politics, technical choices, or any other personal characteristics. No tolerance for bullying, intimidation, personal assault, harassment, continuous interruption of conversations or other activities, and behavior that interferes with the full participation of other participants. This includes sexual harassment, stalking, stalking, harassing photography or recording, inappropriate physical contact, unwelcome sexual attention, vulgar communication, and diminutive characteristics. The policies apply to actual meeting sites and conference venues, including both physical venues, online venues, and official virtual platforms, including but not limited to Openreview comments, video, virtual streaming, Q&A tools. For example, offensive or even threatening comments in Open review are prohibited. Also, Zoom bombing or any virtual activities that have nothing to do with the topic of discussion and that are detrimental to the purpose of the topic or program are not allowed.</p>
    <h5>Action</h5>
     <p>Participants who are asked by any member of the community to stop any improper behavior defined here should immediately comply. Meeting organizers may take further actions at their discretion, including: formally or informally warning offenders, expelling the meeting without refunds, refusing to submit or participate in future CLeaR meetings, reporting the incident to the offender’s local agency or funding agency, or reporting to the local Authorities or law enforcement agencies report the incident. The ''just kidding'' response is unacceptable. If action is taken, an appeal procedure will be provided.</p>
    <h5>Complaint reporting</h5>
     <p>If you have any concerns about possible violation of the policies, please contact the conference chairs (chairs.clear2023@gmail.com) as soon as possible. Reports made during the conference will be responded to within 24 hours; those at other times in less than two weeks. Complaints and violations will be handled with discretion. We are ready and eager to help attendees contact relevant help services, escort them to a safe place, or to otherwise help people who have been harassed feel safe during the meeting. We gratefully accept feedback from the CLeaR community on our policy and actions.</p>
     <p>A similar version has been used by other conferences, such as ICLR 2020 and UAI 2020.</p>
    </div>

  );
};

export default CodeConduct2023page;
