import React from "react";
import Title from "../../../components/Title/Title";

const AreaChair2024page = () => {
  return (
    <div>
      <Title>Area Chairs</Title>
      <p> TBD </p>
    </div>
  )
}

export default AreaChair2024page
