import React from 'react'
import DatesTable from './components/DateTable/DatesTable'
import Title from '../../../components/Title/Title'
import { importantDates2025 } from '../../../common/constants/importantDates'

const ImportantDates2025page = () => {
  return (
    <>
      <Title>Important Dates</Title>
      <DatesTable array={importantDates2025} />
    </>
  )
}

export default ImportantDates2025page;
