import React from "react";
import Title from "../../../components/Title/Title";
import './RegistrationPage.scss';

const Registration2022page = () => {
  return (
    <div>
      <Title>Venue and Registration</Title>
      <p>CLeaR 2022 will be held  at the <a HREF="https://www.visitredwoods.com/listing/sequoia-conference-center/717/"><b>Sequoia Conference Center (SCC)</b></a>, <b>Eureka, CA,</b> USA on <b>April 11-13</b>, 2022. We encourage in-person participation, but the conference also has virtual elements. 
      We are happy to announce that CLeaR will be co-located with a CIFAR/Sloan invitation-based causality workshop. This workshop is organized by CIFAR in conjunction with the Learning in Machines and Brains program directed by Yoshua Bengio and Yann LeCun. CLeaR participants are welcome to attend all sessions of the workshop as well. </p>

      <p>Please register for the CLeaR 2022 <a HREF="">here</a> (registration closed). Please let us know if you need letters for visa application.  </p>

      <p>We would like to mention that a number of authors and a majority of the organizing committee (including Bernhard Schölkopf, Caroline Uhler, Kun Zhang, Francesco Locatello, Frederick Eberhardt, Daniel Malinsky, Sara Magliacane, Chandler Squires, Patrick Burauel, Elizabeth Ogburn, and Julius von Kügelgen) have expressed their intention to attend in person. Below is the nearby hotel information: </p>
      <table className="RegistrationPage__table" border="1">
        <tr>
          <th>Name</th>
          <th>Distance to SCC </th>
          <th>Price (per room per night)</th>
          <th>Number of rooms (estimate)</th>
        </tr>
        <tr>
          <td><a HREF="https://www.humboldtbayinn.com/deals.html">Best Western Plus, Humboldt Bay Inn</a></td>
          <td>1.5 miles</td>
          <td>140</td>
          <td>10+</td>
        </tr>
        <tr>
          <td><a HREF="https://hotelarcata.com/">Hotel Arcata</a></td>
          <td>7.3 miles</td>
          <td>220+</td>
          <td>20</td>
        </tr>
        <tr>
          <td><a HREF="https://www.ihg.com/holidayinnexpress/hotels/us/en/eureka/ekaex/hoteldetail?cm_mmc=GoogleMaps-_-EX-_-US-_-EKAEX">Holiday Inn Express & Suites</a></td>
          <td>2.1 miles</td>
          <td>150+</td>
          <td>10+</td>
        </tr>
        <tr>
          <td><a HREF="https://www.choicehotels.com/en-uk/california/eureka/clarion-hotels/ca811 ">Clarion Hotel by Humboldt Bay </a></td>
          <td>0.4 miles</td>
          <td>112+</td>
          <td>35+</td>
        </tr>
        <tr>
          <td><a HREF=" https://www.historiceaglehouse.com/contact-us "> The Inn at 2nd & C </a></td>
          <td>1.4 miles</td>
          <td>149+</td>
          <td>15+</td>
        </tr>
        <tr>
          <td><a HREF=" https://carterhouse.com/ "> Carter House Inns</a></td>
          <td>0.8 miles</td>
          <td>225</td>
          <td>15+</td>
        </tr>
      </table>
      <br />
    </div>
  )
}

export default Registration2022page
