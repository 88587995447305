import React from "react";
import Title from "../../../components/Title/Title";
import PaperTable from "./components/PaperTable/PaperTable";
import acceptedPapers_oral from "../../../common/constants/acceptedPapers_oral";
import acceptedPapers_poster from "../../../common/constants/acceptedPapers_poster";

const AcceptedPapers2022page = () => {
  return (
    <div>
      <Title>Accepted Papers</Title>
      <h5><b>Oral</b></h5>
      <PaperTable array={acceptedPapers_oral} />
      <h5><b>Poster</b></h5>
      <PaperTable array={acceptedPapers_poster} />
    </div>

  );
};

export default AcceptedPapers2022page;
