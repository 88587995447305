/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link, useLocation } from 'react-router-dom';
import './DropdownItem.scss'

export const YearItem = ({dropdown, items}) => {
  return (
    <div className="nav-item dropright">
      <a
        className="nav-link dropdown-toggle"
        id={dropdown}
        data-toggle="dropdown"
        data-display="relative"
        aria-haspopup="true"
        aria-expanded="false"
      >
        {dropdown}
      </a>
      <div className="dropdown-menu" aria-labelledby={dropdown}>
        {items.map((item) => (
          <Link className="dropdown-item" to={`/${item.split(' ').join('')}`}>
            {item}
          </Link>
        ))}
      </div>
    </div>
  );
};

export const DropdownItem = ({dropdown, items}) => {
  const year = useLocation().pathname.split('/')[1]
  return (
    <div className="nav-item dropright">
      <a
        className="nav-link dropdown-toggle"
        id={dropdown}
        data-toggle="dropdown"
        data-display="relative"
        aria-haspopup="true"
        aria-expanded="false"
      >
        {dropdown}
      </a>
      <div className="dropdown-menu" aria-labelledby={dropdown}>
        {items.map((item) => (
          <Link className="dropdown-item" to={`/${year + '/'+ item.split(' ').join('')}`}>
            {item}
          </Link>
        ))}
      </div>
    </div>
  );
};

