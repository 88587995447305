import React from "react";
import Title from "../../../components/Title/Title";
import './RegistrationPage.scss';

const Registration2023page = () => {
  return (
    <div>
      <Title>Venue and Registration</Title>
      <h5>Venue</h5>
      <p>CLeaR 2023 will be held  at the <a HREF="https://are01.safelinks.protection.outlook.com/?url=https%3A%2F%2Fwww.google.com%2Fsearch%3Fclient%3Dfirefox-b-e%26q%3Damazon%2520tubingen%26tbs%3Dlf%3A1%2Clf_ui%3A4%26tbm%3Dlcl%26sxsrf%3DALiCzsaKSXCZ5uNhuteyfNgLS-33hqtNzA%3A1671723565176%26rflfq%3D1%26num%3D10%26rldimm%3D4531902404333983686%26lqi%3DCg9hbWF6b24gdHViaW5nZW4iA4gBAUjFreaf8bWAgAhaHRAAGAAYASIPYW1hem9uIHR1YmluZ2VuKgQIAhAAkgEQY29ycG9yYXRlX29mZmljZaoBDhABKgoiBmFtYXpvbigA%26ved%3D2ahUKEwjV-Y7Kx438AhXLgv0HHfT3CmcQvS56BAgZEAE%26sa%3DX%26rlst%3Df&data=05%7C01%7CGuangyi.Chen%40mbzuai.ac.ae%7C8417162c082746dada9d08dae4332fcb%7Cc93272d31b074b3da3b619b34a973915%7C0%7C0%7C638073207233670863%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C2000%7C%7C%7C&sdata=%2FzuuOJqor7ZquBixuGXnG0zULXD6ZFhUZ47BbIk19SY%3D&reserved=0"><b>Amazon Development Center</b></a>, <b>Tübingen</b>, Germany on <b>April 11-14</b>, 2023. We encourage in-person participation, but the conference also has virtual elements. </p>
      <h5>Registration</h5>
      <p>Please register for the CLeaR 2023 <a HREF="https://clear.regfox.com/cclear2023registration">here</a> (registration closes March 15). Please let us know if you need letters for visa application.  </p>
      <h5>Your way to Tuebingen</h5>
      <p>Please check out the description of the Max-Planck-Institute for Intelligent Systems on how to get to Tuebingen using public transport <a HREF="https://is.mpg.de/en/contact">here</a>. </p>
      <h5>Public transport in Tuebingen</h5>
      <p>You will receive a public transport ticket during your registration, which is valid during the conference period (11th to the 14th), but only for the city busses in Tuebingen (it is not valid all the way to Stuttgart or the airport). Note: You need to get to the registration by buying a one-way ticket! </p>
      <p>Google maps’ public transport suggestions work fairly well in Tuebingen. There are multiple bus lines that bring you to the venue or close to it.  </p>
      <p>The easiest is bus line 3 towards Waldhäuser Ost, get off the bus at “Max-Planck-Institute” and you will see the Amazon building right away.</p>
      <img src={require('../../../assets/Picture1.png').default} width="400" alt="Google Map Image for option 1"/>
      <p>Other options are bus lines 4 or 6, also towards Waldhäuser Ost, bring you close to the venue as well. However, you will need to walk from there for up to 10 minutes to get to the venue (get off at “Winkelwiese”).</p>
      <img src={require('../../../assets/Picture2.png').default} width="400" alt="Google Map Image for option 2"/>
      <p>Bus line 17 also works (get off at “Robert-Gradmann-Weg” and continue walking up the hill).</p>
      <img src={require('../../../assets/Picture3.png').default} width="400" alt="Google Map Image for option 3"/>
      <h5>Hotel Accommodations</h5>
      <p>We do not partner with any hotel in Tuebingen. Here is an incomplete list: </p>
      <ul>
        <li><a href="https://www.koncepthotels.com/en/neue-horizonte-tuebingen/">Koncept Hotel Neue Horizonte</a></li>
        <li><a href="https://hotel-meteora.de/en">Hotel Meteora</a></li>
        <li><a href="https://all.accor.com/hotel/9841/index.en.shtml">Ibis styles Tuebingen</a></li>
        <li><a href="https://www.hotelamschloss.de/en/hotel-2/">Hotel am Schloss Tuebingen</a></li>
        <li><a href="https://www.hotel-domizil.com/en/">Hotel DOMIZIL Tuebingen</a></li>
        <li><a href="https://www.krone-tuebingen.de/en/">Hotel Krone Tuebingen</a></li>
      </ul>
    </div>
  )
}

export default Registration2023page

