import React from "react";
import Title from "../../../components/Title/Title";
import './RegistrationPage.scss';

const Registration2025page = () => {
  return (
    <div>
      <Title>Venue and Registration</Title>
      <h5>Venue</h5>
      <p>TBA </p>
      <h5>Registration</h5>
      <p>TBA</p>
    </div>
  )
}

export default Registration2025page

