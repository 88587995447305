import React from "react";
import Title from "../../../components/Title/Title";
import './SponsorsPage.scss';

const Sponsors2024page = () => {
  return (
    <div>
    <Title>Sponsors</Title>
  <center>
  <h5 className="SponsorPage__tableTile--gold">Gold Sponsors</h5>
  <table className="SponsorPage__table">
    <tr>
    <td><img src={require('../../../assets/science_hub_banner3.png').default} width="700"/></td>
    </tr>  
  </table>
  <h5 className="SponsorPage__tableTile--silver">Silver Sponsors</h5>
  <table className="SponsorPage__table">
    <tr>
      {/* <td><img src={require('../../../assets/Qualcomm.jpg').default} width="200"/></td> */}
      <td><img src={require('../../../assets/apple_logo.jpg').default} width="200"/></td>
    </tr>  
  </table>
  <h5 className="SponsorPage__tableTile--bronze">Bronze Sponsors</h5>
  <table className="SponsorPage__table">
    <tr>
    <td><img src={require('../../../assets/servicenow_logo.png').default} width="400"/></td>
    </tr>  
  </table>
  </center>
</div>
  )
}

export default Sponsors2024page
