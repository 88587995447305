import React from 'react'
import Title from '../../../components/Title/Title'
import OCTable from './components/OCTable/OCTable'
import { array2024 } from '../../../common/constants/organizingCommittee'


const OrganizingCommittee2024page = () => {
  return (
    <div>
      <Title>Organizing Committee</Title>
      <OCTable array={array2024} />
    </div>
  )
}

export default OrganizingCommittee2024page
