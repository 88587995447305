import React from "react";
import Title from "../../../components/Title/Title";

const AreaChair2023page = () => {
  return (
    <div>
      <Title>Area Chairs</Title>
      Erich Kummerfeld	(Carnegie Mellon University)<br />
      Jiji Zhang	(The Chinese University of Hong Kong)<br />
      Jin Tian	(Iowa State University)<br />
      Joris Mooij	(University of Amsterdam)<br />
      Kun Zhang	(Carnegie Mellon University)<br />
      Lin Liu	(Shanghai Jiao Tong University)<br />
      Mingming Gong	(University of Melbourne)<br />
      Razieh Nabi	(Emory University)<br />
      Rohit Bhattacharya	(Williams College)<br />
      Sach Mukherjee	(University of Cambridge)<br />
      Sara Magliacane	(University of Amsterdam)<br />
      Shohei Shimizu	(Shiga University)<br />
      Simon Lacoste-Julien	(University of Montreal)<br />
      Sofia Triantafillou	(University of Crete)<br />
      Thomas Richardson	(University of Washington)<br />
      Tom Claassen	(Radboud University Nijmegen)<br />
      Vasilis Syrgkanis	(Microsoft)
    </div>
  )
}

export default AreaChair2023page
