const acceptedDatasets = [
  {
    title: "The Structurally Complex with Additive Parent Causality (SCARY) Dataset",
    author:
      "Jarry Chen, Haytham M. Fayek",
    filePath: "/2023/AcceptedDatasets/chen23a.pdf",
  },
  {
    title: "3DIdentBox: A Toolbox for Identifiability Benchmarking",
    author:
      "Alice Bizeul, Imant Daunhawer, Emanuele Palumbo, Bernhard Schölkopf, Alexander Marx, Julia E. Vogt",
    filePath: "/2023/AcceptedDatasets/bizeul23a.pdf",
  },
  {
    title: "Causal Benchmark Based on Disentangled Image Dataset",
    author:
      "Liyuan Xu, Arthur Gretton",
    filePath: "/2023/AcceptedDatasets/xu23a.pdf",
  },
  {
    title: "Synthetic Time Series: A Dataset for Causal Discovery",
    author:
      "Julio Munoz-Benıtez, L. Enrique Sucar",
    filePath: "/2023/AcceptedDatasets/munozbenitez23a.pdf",
  },
  {
    title: "SpaCE: The Spatial Confounding (Benchmarking) Environment",
    author:
      "Mauricio Tec, Ana Trisovic, Michelle Audirac, Francesca Dominici",
    filePath: "/2023/AcceptedDatasets/tec23a.pdf",
  },
  {
    title: "CausalEdu: a real-world education dataset for temporal causal discovery and inference",
    author:
      "Wenbo Gong, Digory Smith, Zichao Wang, Craig Barton, Simon Woodhead, Nick Pawlowski, Joel Jennings, Cheng Zhang",
    filePath: "/2023/AcceptedDatasets/gong23a.pdf",
  },
];

export default acceptedDatasets;
