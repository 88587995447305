import React from "react";
import Title from "../../../components/Title/Title";

const ProgramCommittee2024page = () => {
  return (
    <div>
      <Title>Program Committee</Title>
      
    TBD
    </div>
  )
}

export default ProgramCommittee2024page
