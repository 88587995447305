import React from "react";
import Title from "../../../components/Title/Title";
import OCTable from '../OrganizingCommitteePage/components/OCTable/OCTable'
import {array ,array1} from '../../../common/constants/advisoryboard'

const AdvisoryBoard2025page = () => {
  return (
    <div>
      <Title>Advisory Board</Title>
      <table>
      <tr>
      <td><OCTable array={array} /></td>
      <td><OCTable array={array1} /></td>
      </tr>
      </table>
    </div>
  )
}

export default AdvisoryBoard2025page
