import React from "react";
import Title from "../../../components/Title/Title";

const KeynoteSpeaker2022page = () => {
  return (
    <div>
      <Title>Plenary Speakers</Title>
      <table>

      <tr>- <a href="http://alisongopnik.com/">Alison Gopnik</a>, University of California, Berkeley</tr>

<tr><b>Title:</b> Causal Learning in Children and Computers.</tr>

<tr><p><b>Abstract:</b> I will describe our research showing how even very young human children engage in effective causal inference - discovering new causal relationships through observation and intervention. This includes not only inferring specific causal relationships but the discovery of abstract causal “over-hypotheses” , variable discovery, analogical reasoning and active learning through exploration. I will discuss implications for causal learning in AI systems.</p></tr>


<tr>- <a href="http://bayes.cs.ucla.edu/jp_home.html">Judea Pearl</a>, University of California, Los Angeles </tr>

<tr><b>Title:</b> The Science of Cause and Effect: From Deep Learning to Deep Understanding</tr>

<tr><p><b>Abstract:</b> We will define "deep understanding" as the capacity to answer questions at all three levels of the reasoning hierarchy: predictions, actions, and imagination. Accordingly I will describe a language, calculus and algorithms that facilitate all three modes of reasoning, using a data + model symbiosis. The talk will then summarize several reasoning tasks that have benefitted from this calculus, including attribution, mediation, data-fusion and missing-data. I will conclude with future horizons, which include: automated scientific explorations, personalized decision making and social intelligence.</p></tr>


<tr>- <a href="https://www.hsph.harvard.edu/james-robins/">James Robins</a>, Harvard School of Public Health </tr>
<tr><b>Title:</b>The Bold Vision of Artificial Intelligence and Philosophy: Directed Acyclic Graphs and Causal Discovery from Non-Independence Constraints</tr>
<tr><p><b>Abstract:</b> My talk is a Parable of Statistics and Big Data wherein our hero pulls a rabbit out of the hat without first putting a rabbit into the hat and thereby wins the Nobel Prize in Biology.</p></tr>


<tr>- <a href="https://www.cmu.edu/dietrich/philosophy/people/faculty/spirtes.html">Peter Spirtes</a>, Carnegie Mellon University </tr>

<tr><b>Title:</b> Inferring Causal Relations from Sample Data</tr>

<tr><p><b>Abstract:</b> I will present a broad overview of the assumptions, types of algorithms, and the limitations of algorithms for inferring causal structures from sample data, along with some illustrations of actual applications. I will explain how the different assumptions  and kinds of data (e.g. no latent confounders, parametric constraints, and i.i.d. data) affect inference, and some recent advances that have been made in relaxing the assumptions that have been commonly made in the past. I will briefly relate causal inference to some problems in Artificial Intelligence.</p></tr>
</table>
    </div>
  )
}

export default KeynoteSpeaker2022page
