import React from "react";
import Title from "../../../components/Title/Title";

const FullAgenda2023page = () => {
  return (
    <div>
      <Title>Full Agenda</Title>
      <p>The table discussion results on <i>Causality in the time of LLMs</i> are released. PLease find the details <a href="https://www.cclear.cc/2023/CLeaR23_roundtable_discussion.pdf"> here</a></p>
      <h5><font color="darkcyan">Day 1 (April 11, Tuesday)</font></h5>
      <table>
      <tr><b>12:00-1:00 PM Registration </b>
      {/* <ul>
      <li><b>Panellists:</b> Elizabeth Ogburn, Thomas Richardson, Eric Tchetgen Tchetgen, Caroline Uhler </li>
      </ul> */}
      </tr>
      <tr><b>1:00-1:30 PM Welcome</b>
      {/* <ul>
      <li>Judea Pearl (University of California, Los Angeles): The Science of Cause and Effect: From Deep Learning to Deep Understanding</li>
      </ul> */}
      </tr>
      <tr><b>1:30-2:30 PM Keynote by Miguel Hernan</b>
      <ul>
      <li>Causal AI for data analysis in the health sciences</li>
      </ul>
      </tr>
      <tr><b>2:30-4:00 PM Poster I</b></tr>
      <ul>
      <li>Poster I.1. Sample-Specific Root Causal Inference with Latent Variables</li>
      <li>Poster I.2. Causal Discovery for Non-stationary Non-linear Time Series Data Using Just-In-Time Modeling</li>
      <li>Poster I.3. Causal Models with Constraints</li>
      <li>Poster I.4. Non-parametric identifiability and sensitivity analysis of synthetic control models</li>
      <li>Poster I.5. Factorization of the Partial Covariance in Singly-Connected Path Diagrams</li>
      <li>Poster I.6. Estimating long-term causal effects from short-term experiments and long-term observational data with unobserved confounding</li>
      <li>Poster I.7. Learning Causal Representations of Single Cells via Sparse Mechanism Shift Modeling</li>
      <li>Poster I.8. Scalable Causal Discovery with Score Matching</li>
      <li>Poster I.9. Instrumental Processes Using Integrated Covariances</li>
      </ul>
      <tr><b>4:00-5:00 PM Oral I</b></tr>
      <ul>
      <li>Oral I.1. Generalizing Clinical Trials with Convex Hulls</li>
      <li>Oral I.2. Backtracking Counterfactuals</li>
      <li>Oral I.3. Stochastic Causal Programming for Bounding Treatment Effects</li>
      </ul>
      <tr><b>Evening (onwards) Social Activity I</b></tr>
      {/* <tr><b>14:00-15:00 CLeaR talks: Causal discovery and inference (17 minutes + 3-minute Q&A for each talk)</b><br />
      <ul>
      <li>Differentially Private Estimation of Heterogeneous Causal Effects; by Fengshi Niu, Harsha Nori, Brian Quistorff, Rich Caruana, Donald Ngwe, Aadharsh Kannan</li>
      <li>Interactive rank testing by betting; by Boyan Duan, Aaditya Ramdas, Larry Wasserman</li>
      <li>Typing assumptions improve identification in causal discovery; by Philippe Brouillard, Perouz Taslakian, Alexandre Lacoste, Sebastien Lachapelle, Alexandre Drouin </li>
      </ul>
      </tr> */}
      {/* <tr><b>15:30-15:45 CLeaR spotlights: Poster session 1 (in person; 1.5 minutes for each spotlight)</b><br />
      <ul>
        <li>A.1.  Amortized Causal Discovery: Learning to Infer Causal Graphs from Time-Series Data; by Sindy Löwe, David Madras, Richard Zemel, Max Welling</li>
        <li>A.2.  A Distance Covariance-based Kernel for Nonlinear Causal Clustering in Heterogeneous Populations; by Alex Markham, Richeek Das, Moritz Grosse-Wentrup</li>
        <li>A.3.  Differentiable Causal Discovery Under Latent Interventions; by Gonçalo Rui Alves Faria, Andre Martins, Mario A. T. Figueiredo</li>
        <li>A.4.  Process Independence Testing in Proximal Graphical Event Models; by Debarun Bhattacharjya, Karthikeyan Shanmugam, Tian Gao, Dharmashankar Subramanian</li>
        <li>A.5.  Predictive State Propensity Subclassification (PSPS): A causal inference algorithm for data-driven propensity score stratification; by Joseph Kelly, Jing Kong, Georg M. Goerg</li>
        <li>A.6.  A Uniformly Consistent Estimator of non-Gaussian Causal Effects Under the k-Triangle-Faithfulness Assumption; by Shuyan Wang, Peter Spirtes</li>
        <li>A.7.  Causal Discovery in Linear Structural Causal Models with Deterministic Relations; by Yuqin Yang, Mohamed S Nafea, AmirEmad Ghassami, Negar Kiyavash</li>
        <li>A.8.  Causal Imputation via Synthetic Interventions; by Chandler Squires, Dennis Shen, Anish Agarwal, Devavrat Shah, Caroline Uhler</li>
        <li>A.9.  Causal Bandits without prior knowledge using separating sets; by Arnoud De Kroon, Joris Mooij, Danielle Belgrave</li>
        <li>A.10. Data-driven exclusion criteria for instrumental variable studies; by Tony Liu, Patrick Lawlor, Lyle Ungar, Konrad Kording</li>
      </ul>
      </tr>
      <tr><b>15:45-16:00 CLeaR spotlights: Poster session 2 (remote; 1.5 minutes for each spotlight)</b><br />
      <ul>
        <li>B.1.  A Multivariate Causal Discovery based on Post-Nonlinear Model; by Kento Uemura, Takuya Takagi, Kambayashi Takayuki, Hiroyuki Yoshida, Shohei Shimizu</li>
        <li>B.2.  Bivariate Causal Discovery via Conditional Divergence; by Bao Duong, Thin Nguyen</li>
        <li>B.3.  Diffusion Causal Models for Counterfactual Estimation; by Pedro Sanchez, Sotirios A. Tsaftaris</li>
        <li>B.4.  Equality Constraints in Linear Hawkes Processes; by Søren Wengel Mogensen</li>
        <li>B.5.  Local Constraint-Based Causal Discovery under Selection Bias; by Philip Versteeg, Joris Mooij, Cheng Zhang </li>
        <li>B.6.  On the Equivalence of Causal Models: A Category-Theoretic Approach; by Jun Otsuka, Hayato Saigo</li>
        <li>B.7.  CausalCity: Complex Simulations with Agency for Causal Discovery and Reasoning; by Daniel McDuff, Yale Song, Jiyoung Lee, Vibhav Vineet, Sai Vemprala, Nicholas Alexander Gyde, Hadi Salman, Shuang Ma, Kwanghoon Sohn, Ashish Kapoor</li>
        <li>B.8.  Causal Discovery for Linear Mixed Data; by Yan Zeng, Shohei Shimizu, Hidetoshi Matsui, Fuchun Sun</li>
        <li>B.9.  Identifying Principal Stratum Causal Effects Conditional on a Post-treatment Intermediate Response; by Xiaoqing Tan, Judah Abberbock, Priya Rastogi, Gong Tang</li>
        <li>B.10. Integrative R-learner of heterogeneous treatment effects combining experimental and observational studies; by Lili Wu, Shu Yang</li>
      </ul>
      </tr>
      <tr><b>16:00-16:45 CLeaR poster session 1 (in person)</b>
      </tr>
      <tr><b>16:45-17:30 CLeaR poster session 2 (online via Zoom breakout rooms)</b>
      </tr> */}
      </table>
      <br />
      <h5><font color="darkcyan">Day 2 (April 12, Wednesday)</font></h5>
      <table>
      <tr><b>9:00-9:30 AM Arrival + coffee</b></tr>
      <tr><b>9:30-10:30 AM Keynote by Negar Kiyavash</b></tr>
      <ul>
      <li>Causal Identification: Are We There Yet?</li>
      </ul>
      <tr><b>10:30-11:00 AM Coffee</b></tr>
      {/* <ul>
      <li><b>Panellists:</b> Stefan Bauer, Rosemary Nan Ke, Negar Kiyavash, David Lopez-Paz </li>
      </ul> */}
      <tr><b>11:00-12:30 PM Poster II</b></tr>
      <ul>
      <li>Poster II.1. Branch-Price-and-Cut for Causal Discovery</li>
      <li>Poster II.2. Causal Discovery with Score Matching on Additive Models with Arbitrary Noise</li>
      <li>Poster II.3. Beyond the Markov Equivalence Class: Extending Causal Discovery under Latent Confounding</li>
      <li>Poster II.4. Learning Conditional Granger Causal Temporal Networks</li>
      <li>Poster II.5. Practical Algorithms for Orientations of Partially Directed Graphical Models</li>
      <li>Poster II.6. Enhancing Causal Discovery from Robot Sensor Data in Dynamic Scenarios</li>
      <li>Poster II.7. Leveraging Causal Graphs for Blocking in Randomized Experiments</li>
      <li>Poster II.8. Generalizing Clinical Trials with Convex Hulls</li>
      <li>Poster II.9. Backtracking Counterfactuals</li>
      <li>Poster II.10. Stochastic Causal Programming for Bounding Treatment Effects</li>
      </ul>
      <tr><b>12:30-2:30 PM Lunch</b></tr>
      <tr><b>2:30-3:30 PM Keynote by Aapo Hyvarinen</b></tr>
      <ul>
      <li>Causal discovery and latent-variable models</li>
      </ul>
      <tr><b>3:30-4:00 PM Townhall Meeting</b></tr>
      <tr><b>4:00-5:00 PM Panel discussion: Datasets</b></tr>
      <tr><b>5:00-6:00 PM Oral II</b></tr>
      <ul>
      <li>Oral II.1. Distinguishing Cause from Effect on Categorical Data: The Uniform Channel Model</li>
      <li>Oral II.2. Jointly Learning Consistent Causal Abstractions Over Multiple Interventional Distributions</li>
      <li>Oral II.3. An Algorithm and Complexity Results for Causal Unit Selection</li>
      </ul>
      <tr><b>Evening (onwards) Dinner / get-together</b></tr>
      </table>
      <br />
      <h5><font color="darkcyan">Day 3 (April 13, Thursday)</font></h5>
      <table>
      <tr><b>9:00-9:30 AM Arrival + coffee</b></tr>
      <tr><b>9:30-10:30 AM Breakout session</b></tr>
      <tr><b>10:30-11:00 AM Coffee</b></tr>
      {/* <ul>
      <li><b>Panellists:</b> Stefan Bauer, Rosemary Nan Ke, Negar Kiyavash, David Lopez-Paz </li>
      </ul> */}
      <tr><b>11:00-12:30 PM Poster III</b></tr>
      <ul>
      <li>Poster III.1. A Meta-Reinforcement Learning Algorithm for Causal Discovery</li>
      <li>Poster III.2. Causal Inference Despite Limited Global Confounding via Mixture Models</li>
      <li>Poster III.3. Causal Triplet: An Open Challenge for Intervention-centric Causal Representation Learning</li>
      <li>Poster III.4. Image-based Treatment Effect Heterogeneity</li>
      <li>Poster III.5. Causal Learning through Deliberate Undersampling</li>
      <li>Poster III.6. Influence-Aware Attention for Multivariate Temporal Point Processes</li>
      <li>Poster III.7. Evaluating Temporal Observation-Based Causal Discovery Techniques Applied to Road Driver Behaviour</li>
      <li>Poster III.8. Directed Graphical Models and Causal Discovery for Zero-Inflated Data</li>
      <li>Poster III.9. An Algorithm and Complexity Results for Causal Unit Selection</li>
      <li>Poster III.10. Local Dependence Graphs for Discrete Time Processes</li>
      </ul>
      {/* <ul>
      <li>Peter Spirtes (Carnegie Mellon University): Inferring Causal Relations from Sample Data</li>
      </ul> */}
      <tr><b>12:30-2:30 PM Lunch</b></tr>
      <tr><b>2:30-6:00 PM Social Activity II</b></tr>
      {/* <tr><b>14:00-15:00 CLeaR talks: Causality and other learning problems (17 minutes + 3-minute Q&A for each talk)</b>
      <ul>
      <li>Learning Casual Overhypotheses through Exploration in Children and Computational Models; by Eliza Kosoy, Jasmine L Collins, David Chan, Jessica B Hamrick, Rosemary Nan Ke, Sandy Huang, Adrian Liu, John Canny, Alison Gopnik </li>
      <li>Non-parametric Inference Adaptive to Intrinsic Dimension; by Khashayar Khosravi, Greg Lewis, Vasilis Syrgkanis</li>
      <li>Evidence-Based Policy Learning; by Jann Spiess, Vasilis Syrgkanis </li>
      </ul>
      </tr> */}
      {/* <tr><b>9:30-10:30 Panel 3: Systems & applications</b></tr>
      <ul>
      <li><b>Panellists:</b> Victor Chernozhukov, Doina Precup, David Sontag, Eric Xing  </li>
      </ul>
      <tr><b>11:00-11:45 Plenary talk</b></tr>
      <ul>
      <li>James Robins (Harvard School of Public Health): The Bold Vision of Artificial Intelligence and Philosophy: Directed Acyclic Graphs and Causal Discovery from Non-Independence Constraints </li>
      </ul>
      <tr><b>12:00-14:00 Lunch</b></tr>
      <tr><b>14:00-15:00 CLeaR talks: Causality and ethical AI (17 minutes + 3-minute Q&A for each talk) </b>
      <ul>
      <li>Causal Explanations and XAI ; by Sander Beckers</li>
      <li>Optimal Training of Fair Predictive Models; by Razieh Nabi, Daniel Malinsky, Ilya Shpitser</li>
      <li>Selection, Ignorability and Challenges With Causal Fairness; by Jake Fawkes, Robin Evans, Dino Sejdinovic </li>
      </ul>
      </tr>
      <tr><b>15:30-15:45 CLeaR spotlights: Poster session 3 (in person; 1.5 minutes for each spotlight)</b>
      <ul>
      <li>C.1.  Weakly Supervised Discovery of Semantic Attributes; by Ameen Ali Ali, Tomer Galanti, Evgenii Zheltonozhskii, Chaim Baskin, Lior Wolf</li>
      <li>C.2.  Attainability and Optimality: The Equalized Odds Fairness Revisited; by Zeyu Tang, Kun Zhang </li>
      <li>C.3.  Causal Structure Discovery between Clusters of Nodes Induced by Latent Factors; by Chandler Squires, Annie Yun, Eshaan Nichani, Raj Agrawal, Caroline Uhler</li>
      <li>C.4.  Disentanglement via Mechanism Sparsity Regularization: A New Principle for Nonlinear ICA; by Sebastien Lachapelle, Pau Rodriguez, Yash Sharma, Katie E Everett, Rémi LE PRIOL, Alexandre Lacoste, Simon Lacoste-Julien</li>
      <li>C.5.  Relational Causal Models with Cycles: Representation and Reasoning; by Ragib Ahsan, David Arbour, Elena Zheleva</li>
      <li>C.6.  Same Cause; Different Effects in the Brain; by Mariya Toneva, Jennifer Williams, Anand Bollu, Christoph Dann, Leila Wehbe</li>
      <li>C.7.  Cause-effect inference through spectral independence in linear dynamical systems: theoretical foundations; by Michel Besserve, Naji Shajarisales, Dominik Janzing, Bernhard Schölkopf</li>
      <li>C.8.  Disentangling Controlled Effects for Hierarchical Reinforcement Learning; by Oriol Corcoll, Raul Vicente</li>
      <li>C.9.  Identifying Coarse-grained Independent Causal Mechanisms with Self-supervision; by Xiaoyang Wang, Klara Nahrstedt, Oluwasanmi O Koyejo</li>
      <li>C.10. Learning Invariant Representations with Missing Data; by Mark Goldstein, Joern-Henrik Jacobsen, Olina Chau, Adriel Saporta, Aahlad Manas Puli, Rajesh Ranganath, Andrew Miller</li>
      </ul>
      </tr>
      <tr><b>15:45-16:00 CLeaR spotlights: Poster session 4 (remote; 1.5 minutes for each spotlight)</b>
      <ul>
      <li>D.1.  Partial Identification with Noisy Covariates: A Robust Optimization Approach; by Wenshuo Guo, Mingzhang Yin, Yixin Wang, Michael Jordan</li>
      <li>D.2.  Can Humans Be out of the Loop? by Junzhe Zhang, Elias Bareinboim</li>
      <li>D.3.  Efficient Reinforcement Learning with Prior Causal Knowledge; by Yangyi Lu, Amirhossein Meisami, Ambuj Tewari</li>
      <li>D.4.  Info Intervention and its Causal Calculus; by Heyang Gong, Ke Zhu</li>
      <li>D.5.  Simple data balancing achieves competitive worst-group-accuracy; by Badr Youbi Idrissi, Martin Arjovsky, Mohammad Pezeshki, David Lopez-Paz</li>
      <li>D.6.  VIM: Variational Independent Modules for Video Prediction; by Rim Assouel, Lluis Castrejon, Aaron Courville, Nicolas Ballas, Yoshua Bengio</li>
      <li>D.7.  Estimating Social Influence from Observational Data; by Dhanya Sridhar, Caterina De Bacco, David Blei</li>
      <li>D.8.  Fair Classification with Instance-dependent Label Noise; by Songhua Wu, Mingming Gong, Bo Han, Yang Liu, Tongliang Liu</li>
      <li>D.9.  Some Reflections on Drawing Causal Inference using Textual Data: Parallels Between Human Subjects and Organized Texts; by Bo Zhang, Jiayao Zhang</li>
      <li>D.10. Towards efficient representation identification in supervised learning; by Kartik Ahuja, Divyat Mahajan, Vasilis Syrgkanis, Ioannis Mitliagkas</li>
      </ul>
      </tr>
      <tr><b>16:00-17:30 CLeaR poster session 3 (in person); CLeaR poster session 4 (online via Zoom breakout rooms)</b></tr> */}
      </table>
      <br />
      <h5><font color="darkcyan">Day 4 (April 14, Friday)</font></h5>
      <table>
      <tr><b>9:00-9:30 AM Arrival + coffee</b></tr>
      <tr><b>9:30-10:30 AM Oral III</b></tr>
      <ul>
      <li>Oral III.1. Directed Graphical Models and Causal Discovery for Zero-Inflated Data</li>
      <li>Oral III.2. Causal Abstraction with Soft Interventions</li>
      </ul>
      <tr><b>10:30-11:00 AM Coffee</b></tr>
      <tr><b>11:00-12:30 PM Poster IV</b></tr>
      <ul>
      <li>Poster IV.1. On Discovery of Local Independence over Continuous Variables via Neural Contextual Decomposition</li>
      <li>Poster IV.2. Local Causal Discovery for Estimating Causal Effects</li>
      <li>Poster IV.3. Can Active Sampling Reduce Causal Confusion in Offline Reinforcement Learning?</li>
      <li>Poster IV.4. Causal Inference with Non-IID Data under Model Uncertainty</li>
      <li>Poster IV.5. Factual Observation Based Heterogeneity Learning for Counterfactual Prediction</li>
      <li>Poster IV.6. On the Interventional Kullback-Leibler Divergence</li>
      <li>Poster IV.7. Unsupervised Object Learning via Common Fate</li>
      <li>Poster IV.8. Distinguishing Cause from Effect on Categorical Data: The Uniform Channel Model</li>
      <li>Poster IV.9. Jointly Learning Consistent Causal Abstractions Over Multiple Interventional Distributions</li>
      <li>Poster IV.10. Causal Abstraction with Soft Interventions</li>
      </ul>
      <tr><b>12:30-1:00 PM Wrap-up</b></tr>
      <tr><b>1:00-2:30 PM Lunch</b></tr>
      </table>
      <br />
    </div>
  )
}

export default FullAgenda2023page
