import React from "react";
import Title from "../../../components/Title/Title";

const ACInstruction2024page = () => {
  return (
    <div>
      <Title>AC Instructions</Title>
    <p>Thank you for serving as an area chair for CLeaR 2024. As an area chair, your job is to (1) ensure that all the submissions you are assigned have high-quality reviews and good discussions, and (2) write quality meta-reviews and make acceptance decisions. If there is any question, please contact the program chairs chairs.clear2024@gmail.com. The main tasks of area chairs are listed below.</p>
    <h5>Main tasks</h5>
      <ol>
      <li><b>Preparation (by Oct 28, 2023)</b></li>
        <ul>
          <li>CLeaR 2024 is using the OpenReview System. Please create your OpenReview profile if you do not have one and make sure it is up to date if you already have an account. </li>
          <li>Please read and agree to CleaR 2024 <a href="CodeofConduct">codes of conduct</a> and declare the right <a href="ConflictsofInterest">conflicts of interests</a>.</li>
          <li>In addition to the guidelines below, please be familiar with the reviewer instructions. You will be interacting significantly with reviewers, so please make sure you understand what is expected of them.</li>
        </ul>
         <li><b>Bid on papers (Oct 31, 2023 - Nov 4, 2023)</b></li>
        <ul>
          <li>Log into OpenReview and bid on submissions that fall into your area of expertise. Your bidding is an important input to the overall matching results. </li>
        </ul>
        <li><b>Check reviewer assignment (Nov 7, 2023 - Nov 9, 2023)</b></li>
        <ul>
          <li>Make sure that every submission in your batch is matched with suitable reviewers before the reviewing process starts. </li>
          <li>If you notice a conflict of interest with a submission that is assigned to you, please contact the program chairs immediately so that the paper will be reassigned.</li>
          <li>You can invite extra reviewers, either in the existing pool or by inviting external reviewers, even after the reviewing process starts.</li>
        </ul>
        <li><b>Make sure all papers have quality reviews (Nov 29, 2023 - Dec 2, 2023)</b></li>
        <ul>
          <li>Initial reviews are due Monday, Nov 29. You might need to send multiple reminder emails. If a reviewer is unable to deliver a review, please find a replacement reviewer who is able to do emergency reviews. </li>
          <li>Read all reviews carefully. If a review is substandard, you should ask the reviewer to improve their review. </li>
        </ul>
        <li><b>Discuss with reviewers and authors (Dec 12, 2023 -- Dec 30, 2023)</b></li>
        <ul>
          <li>As soon as the discussion period starts, initiate and lead a discussion via OpenReview for each submission, and make sure the reviewers engage in the discussion phase. </li>
          <li>Make sure your reviewers read and respond to all author responses. </li>
          <li>A further discussion with the authors will be enabled during the discussion period. Please be cautious when posting a comment about who can see the discussions, only the reviewers or both reviewers and authors.</li>
        </ul>
        <li><b>Make accept/reject decisions: (Jan 2, 2024 - Jan 9, 2024)</b></li>
        <ul>
          <li>Write a meta-review that explains your decision (accept or reject) to the authors. Your comments should augment the reviews, and explain how the reviews, author response, and discussion were used to arrive at your decision. Do not dismiss or ignore a review unless you have a good reason for doing so. If the reviewers cannot come to a consensus, you should read the paper carefully and write a detailed meta-review. </li>
          <li>If you cannot make a decision, please reach out to the program chairs at your earliest convenience.</li>
        </ul>
      </ol>
    
      <p> * <b>Please review the policies in the CLeaR 2024 <a href="CallforPapers">Call for Papers</a> and <a href="ReviewerInstructions">Reviewer Instructions</a>.</b> </p>
    </div>
  )
}

export default ACInstruction2024page
