import React from 'react'
import { Switch, Route, Redirect } from 'react-router'

import ImportantDates2022page from '../../pages/2022/ImportantDatesPage'
import AcceptedPapers2022page from '../../pages/2022/AcceptedPapersPage'
import OrganizingCommittee2022page from '../../pages/2022/OrganizingCommitteePage'
import ReviewerInstruction2022page from '../../pages/2022/ReviewerInstructionPage'
import AdvisoryBoard2022page from '../../pages/2022/AdvisoryBoardPage'
import Sponsors2022page from '../../pages/2022/SponsorsPage'
import AreaChair2022page from '../../pages/2022/AreaChairPage'
import ProgramCommittee2022page from '../../pages/2022/ProgramCommitteePage'
import CLeaR2022Page from '../../pages/2022/CLeaRPage'
import FullAgenda2022page from '../../pages/2022/FullAgendaPage'
import KeynoteSpeaker2022page from '../../pages/2022/KeynoteSpeakerPage'
import CallforPapers2022page from '../../pages/2022/CallforPapersPage'
import ACInstruction2022page from '../../pages/2022/ACInstructionPage'
import CodeofConduct2022page from '../../pages/2022/CodeConductPage'
import CoI2022page from '../../pages/2022/CoIPage'
import CameraReady2022page from '../../pages/2022/CameraReady'
import Registration2022 from '../../pages/2022/RegistrationPage'

import ImportantDates2023page from '../../pages/2023/ImportantDatesPage'
import AcceptedDatasets2023page from '../../pages/2023/AcceptedDatasetsPage'
import AcceptedPapers2023page from '../../pages/2023/AcceptedPapersPage'
import OrganizingCommittee2023page from '../../pages/2023/OrganizingCommitteePage'
import ReviewerInstruction2023page from '../../pages/2023/ReviewerInstructionPage'
import AdvisoryBoard2023page from '../../pages/2023/AdvisoryBoardPage'
import Sponsors2023page from '../../pages/2023/SponsorsPage'
import AreaChair2023page from '../../pages/2023/AreaChairPage'
import ProgramCommittee2023page from '../../pages/2023/ProgramCommitteePage'
import CLeaR2023Page from '../../pages/2023/CLeaRPage'
import FullAgenda2023page from '../../pages/2023/FullAgendaPage'
import KeynoteSpeaker2023page from '../../pages/2023/KeynoteSpeakerPage'
import CallforPapers2023page from '../../pages/2023/CallforPapersPage'
import CallforDatasets2023page from '../../pages/2023/CallforDatasetsPage'
import ACInstruction2023page from '../../pages/2023/ACInstructionPage'
import CodeofConduct2023page from '../../pages/2023/CodeConductPage'
import CoI2023page from '../../pages/2023/CoIPage'
import CameraReady2023page from '../../pages/2023/CameraReady'
import Registration2023 from '../../pages/2023/RegistrationPage'
import PresentationInstruction2023page from '../../pages/2023/PresentationInstructionPage'
import OnlineSchedule2023page from '../../pages/2023/OnlineSchedulePage'
import SocialActivities2023page from '../../pages/2023/SocialActivitiesPage'
import StudentVolunteers2023page from '../../pages/2023/StudentVolunteersPage'

import ImportantDates2024page from '../../pages/2024/ImportantDatesPage'
import AcceptedDatasets2024page from '../../pages/2024/AcceptedDatasetsPage'
import AcceptedPapers2024page from '../../pages/2024/AcceptedPapersPage'
import OrganizingCommittee2024page from '../../pages/2024/OrganizingCommitteePage'
import ReviewerInstruction2024page from '../../pages/2024/ReviewerInstructionPage'
import AdvisoryBoard2024page from '../../pages/2024/AdvisoryBoardPage'
import Sponsors2024page from '../../pages/2024/SponsorsPage'
import AreaChair2024page from '../../pages/2024/AreaChairPage'
import ProgramCommittee2024page from '../../pages/2024/ProgramCommitteePage'
import CLeaR2024Page from '../../pages/2024/CLeaRPage'
import FullAgenda2024page from '../../pages/2024/FullAgendaPage'
import KeynoteSpeaker2024page from '../../pages/2024/KeynoteSpeakerPage'
import CallforPapers2024page from '../../pages/2024/CallforPapersPage'
import ACInstruction2024page from '../../pages/2024/ACInstructionPage'
import CodeofConduct2024page from '../../pages/2024/CodeConductPage'
import CoI2024page from '../../pages/2024/CoIPage'
import CameraReady2024page from '../../pages/2024/CameraReady'
import Registration2024 from '../../pages/2024/RegistrationPage'
import PresentationInstruction2024page from '../../pages/2024/PresentationInstructionPage'
import OnlineSchedule2024page from '../../pages/2024/OnlineSchedulePage'
import SocialActivities2024page from '../../pages/2024/SocialActivitiesPage'
import StudentVolunteers2024page from '../../pages/2024/StudentVolunteersPage'


import ImportantDates2025page from '../../pages/2025/ImportantDatesPage'
import AcceptedDatasets2025page from '../../pages/2025/AcceptedDatasetsPage'
import AcceptedPapers2025page from '../../pages/2025/AcceptedPapersPage'
import OrganizingCommittee2025page from '../../pages/2025/OrganizingCommitteePage'
import ReviewerInstruction2025page from '../../pages/2025/ReviewerInstructionPage'
import AdvisoryBoard2025page from '../../pages/2025/AdvisoryBoardPage'
import Sponsors2025page from '../../pages/2025/SponsorsPage'
import AreaChair2025page from '../../pages/2025/AreaChairPage'
import ProgramCommittee2025page from '../../pages/2025/ProgramCommitteePage'
import CLeaR2025Page from '../../pages/2025/CLeaRPage'
import FullAgenda2025page from '../../pages/2025/FullAgendaPage'
import KeynoteSpeaker2025page from '../../pages/2025/KeynoteSpeakerPage'
import CallforPapers2025page from '../../pages/2025/CallforPapersPage'
import ACInstruction2025page from '../../pages/2025/ACInstructionPage'
import CodeofConduct2025page from '../../pages/2025/CodeConductPage'
import CoI2025page from '../../pages/2025/CoIPage'
import CameraReady2025page from '../../pages/2025/CameraReady'
import Registration2025 from '../../pages/2025/RegistrationPage'
import PresentationInstruction2025page from '../../pages/2025/PresentationInstructionPage'
import OnlineSchedule2025page from '../../pages/2025/OnlineSchedulePage'
import SocialActivities2025page from '../../pages/2025/SocialActivitiesPage'
import StudentVolunteers2025page from '../../pages/2025/StudentVolunteersPage'


import './Content.scss'


const Content = () => {
  return (
    <div className="col-md-9">
      <div className="Content">
        <Switch>
          <Route exact path='/2022/ImportantDates' component={ImportantDates2022page} />
          <Route exact path='/2022/AcceptedPapers' component={AcceptedPapers2022page} />
          <Route exact path='/2022/OrganizingCommittee' component={OrganizingCommittee2022page} />
          <Route exact path='/2022/ReviewerInstructions' component={ReviewerInstruction2022page} />
          <Route exact path='/2022/ACInstructions' component={ACInstruction2022page} />
          <Route exact path='/2022/AdvisoryBoard' component={AdvisoryBoard2022page} />
          <Route exact path='/2022/Sponsors' component={Sponsors2022page} />
          <Route exact path='/2022/AreaChairs' component={AreaChair2022page} />
          <Route exact path='/2022/ProgramCommittee' component={ProgramCommittee2022page} />
          <Route exact path='/2022/FullAgenda' component={FullAgenda2022page} />
          <Route exact path='/2022/PlenarySpeakers' component={KeynoteSpeaker2022page} />
          <Route exact path='/2022/CallforPapers' component={CallforPapers2022page} />
          <Route exact path='/2022/CodeofConduct' component={CodeofConduct2022page} />
          <Route exact path='/2022/ConflictsofInterest' component={CoI2022page} />
          <Route exact path='/2022/CameraReady' component={CameraReady2022page} />
          <Route exact path='/2022/VenueandRegistration' component={Registration2022} />
          <Route exact path='/2022' component={CLeaR2022Page} />

          <Route exact path='/2023/ImportantDates' component={ImportantDates2023page} />
          <Route exact path='/2023/AcceptedDatasets' component={AcceptedDatasets2023page} />
          <Route exact path='/2023/AcceptedPapers' component={AcceptedPapers2023page} />
          <Route exact path='/2023/OrganizingCommittee' component={OrganizingCommittee2023page} />
          <Route exact path='/2023/ReviewerInstructions' component={ReviewerInstruction2023page} />
          <Route exact path='/2023/ACInstructions' component={ACInstruction2023page} />
          <Route exact path='/2023/AdvisoryBoard' component={AdvisoryBoard2023page} />
          <Route exact path='/2023/Sponsors' component={Sponsors2023page} />
          <Route exact path='/2023/AreaChairs' component={AreaChair2023page} />
          <Route exact path='/2023/ProgramCommittee' component={ProgramCommittee2023page} />
          <Route exact path='/2023/FullAgenda' component={FullAgenda2023page} />
          <Route exact path='/2023/PlenarySpeakers' component={KeynoteSpeaker2023page} />
          <Route exact path='/2023/CallforPapers' component={CallforPapers2023page} />
          <Route exact path='/2023/CallforDatasets' component={CallforDatasets2023page} />
          <Route exact path='/2023/CodeofConduct' component={CodeofConduct2023page} />
          <Route exact path='/2023/ConflictsofInterest' component={CoI2023page} />
          <Route exact path='/2023/CameraReady' component={CameraReady2023page} />
          <Route exact path='/2023/VenueandRegistration' component={Registration2023} />
          <Route exact path='/2023/PresentationInstructions' component={PresentationInstruction2023page} />
          <Route exact path='/2023/OnlineSchedule' component={OnlineSchedule2023page} />
          <Route exact path='/2023/SocialActivities' component={SocialActivities2023page} />
          <Route exact path='/2023/StudentVolunteers' component={StudentVolunteers2023page} />
          <Route exact path='/2023' component={CLeaR2023Page} />

          <Route exact path='/2024/ImportantDates' component={ImportantDates2024page} />
          <Route exact path='/2024/AcceptedDatasets' component={AcceptedDatasets2024page} />
          <Route exact path='/2024/AcceptedPapers' component={AcceptedPapers2024page} />
          <Route exact path='/2024/OrganizingCommittee' component={OrganizingCommittee2024page} />
          <Route exact path='/2024/ReviewerInstructions' component={ReviewerInstruction2024page} />
          <Route exact path='/2024/ACInstructions' component={ACInstruction2024page} />
          <Route exact path='/2024/AdvisoryBoard' component={AdvisoryBoard2024page} />
          <Route exact path='/2024/Sponsors' component={Sponsors2024page} />
          <Route exact path='/2024/AreaChairs' component={AreaChair2024page} />
          <Route exact path='/2024/ProgramCommittee' component={ProgramCommittee2024page} />
          <Route exact path='/2024/FullAgenda' component={FullAgenda2024page} />
          <Route exact path='/2024/PlenarySpeakers' component={KeynoteSpeaker2024page} />
          <Route exact path='/2024/CallforPapers' component={CallforPapers2024page} />
          <Route exact path='/2024/CodeofConduct' component={CodeofConduct2024page} />
          <Route exact path='/2024/ConflictsofInterest' component={CoI2024page} />
          <Route exact path='/2024/CameraReady' component={CameraReady2024page} />
          <Route exact path='/2024/VenueandRegistration' component={Registration2024} />
          <Route exact path='/2024/PresentationInstructions' component={PresentationInstruction2024page} />
          <Route exact path='/2024/OnlineSchedule' component={OnlineSchedule2024page} />
          <Route exact path='/2024/SocialActivities' component={SocialActivities2024page} />
          <Route exact path='/2024/StudentVolunteers' component={StudentVolunteers2024page} />
          <Route exact path='/2024' component={CLeaR2024Page} />

          <Route exact path='/2025/ImportantDates' component={ImportantDates2025page} />
          <Route exact path='/2025/AcceptedDatasets' component={AcceptedDatasets2025page} />
          <Route exact path='/2025/AcceptedPapers' component={AcceptedPapers2025page} />
          <Route exact path='/2025/OrganizingCommittee' component={OrganizingCommittee2025page} />
          <Route exact path='/2025/ReviewerInstructions' component={ReviewerInstruction2025page} />
          <Route exact path='/2025/ACInstructions' component={ACInstruction2025page} />
          <Route exact path='/2025/AdvisoryBoard' component={AdvisoryBoard2025page} />
          <Route exact path='/2025/Sponsors' component={Sponsors2025page} />
          <Route exact path='/2025/AreaChairs' component={AreaChair2025page} />
          <Route exact path='/2025/ProgramCommittee' component={ProgramCommittee2025page} />
          <Route exact path='/2025/FullAgenda' component={FullAgenda2025page} />
          <Route exact path='/2025/PlenarySpeakers' component={KeynoteSpeaker2025page} />
          <Route exact path='/2025/CallforPapers' component={CallforPapers2025page} />
          <Route exact path='/2025/CodeofConduct' component={CodeofConduct2025page} />
          <Route exact path='/2025/ConflictsofInterest' component={CoI2025page} />
          <Route exact path='/2025/CameraReady' component={CameraReady2025page} />
          <Route exact path='/2025/VenueandRegistration' component={Registration2025} />
          <Route exact path='/2025/PresentationInstructions' component={PresentationInstruction2025page} />
          <Route exact path='/2025/OnlineSchedule' component={OnlineSchedule2025page} />
          <Route exact path='/2025/SocialActivities' component={SocialActivities2025page} />
          <Route exact path='/2025/StudentVolunteers' component={StudentVolunteers2025page} />
          <Route exact path='/2025' component={CLeaR2025Page} />


          <Redirect to='/2025' />
        </Switch>
      </div>
    </div>
  )
}

export default Content
