import React from 'react';
import { useLocation } from 'react-router-dom';
import Logo from '../../assets/logo_v3.png';
import './Header.scss';

const Header = () => {
  const year = useLocation().pathname.split('/')[1]
  return (
    <header className="Header">
      <div className="container">
        <img src={Logo} alt="logo" />
          <span>{`${'CLeaR ' + year}`}</span>
        </div>
    </header> 
  )
}

export default Header

