import React from "react";
import Title from "../../../components/Title/Title";
import DatesTable from "../ImportantDatesPage/components/DateTable/DatesTable";
import { importantDates2023 } from "../../../common/constants/importantDates";
import "./CLeaR2023Page.scss";

const CLeaR2023page = () => {
  return (
    <div>
      <Title>CLeaR (Causal Learning and Reasoning) 2023</Title>
      <div class="HomePage__ContainerStyle">
        <p>
          Causality is a fundamental notion in science and engineering. In the
          past few decades, some of the most influential developments in the
          study of causal discovery, causal inference, and the causal treatment
          of machine learning have resulted from cross-disciplinary efforts. In
          particular, a number of machine learning and statistical analysis
          techniques have been developed to tackle classical causal discovery
          and inference problems. On the other hand, the causal view has been
          shown to be able to facilitate formulating, understanding, and
          tackling a number of hard machine learning problems in transfer
          learning, reinforcement learning, and deep learning.
        </p>
        <p>
          CLeaR 2022: Starting a brand new conference in these pandemic years
          and ensuring it is set up for long-term success has been a significant
          undertaking. Despite these challenges, more than 50 people attended the
          conference in person and several hundred connected remotely. We had 9
          oral presentations and 40 posters, covering topics that range from causal
          discovery, causal fairness, explainability, non-parametric inference,
          causal Markov decision processes, to social-influence estimation,
          applications of causality, and other topics. We have received a number
          of enquiries about whether and where to hold CLeaR 2023 and are
          delighted to announce the next edition.
        </p>
        <p>
          We invite submissions to the 2nd conference on Causal Learning and
          Reasoning (CLeaR), and welcome paper submissions that describe new
          theory, methodology, and/or applications relevant to any aspect of
          causal learning and reasoning in the fields of artificial intelligence
          and statistics. Submitted papers will be evaluated based on their
          novelty, technical quality, and potential impact. Experimental methods
          and results are expected to be reproducible, and authors are strongly
          encouraged to make code and data available. We also encourage
          submissions of proof-of-concept research that puts forward novel ideas
          and demonstrates potential for addressing problems at the intersection
          of causality and machine learning. <b>CLeaR 2023 will be held in Tübingen,
          Germany from April 11 to 14, 2023, with virtual elements. </b>
        </p>
        Topics of submission may include, but are not limited to:
        <ul>
        <li>Machine learning building on causal principles</li>
        <li>Causal discovery in complex environments</li>
        <li>Efficient causal discovery in large-scale datasets</li>
        <li>Causal effect identification and estimation</li>
        <li>Causal generative models for machine learning</li>
        <li>
        Unsupervised and semi-supervised deep learning connected to causality
        </li>
        <li>Machine learning with heterogeneous data sources</li>
        <li>Benchmark for causal discovery and causal reasoning</li>
        <li>Reinforcement learning</li>
        <li>
          Fairness, accountability, transparency, explainability,
          trustworthiness, and recourse{" "}
        </li>
        <li>Applications of any of the above to real-world problems</li>
        <li>Foundational theories of causation</li>
        </ul>
        <p>Submit at <a href="https://openreview.net/group?id=cclear.cc/CLeaR/2023/Conference">https://openreview.net/group?id=cclear.cc/CLeaR/2023/Conference</a>.</p> 
      </div>
      <br></br>
      <div>
      <h5> Updates </h5>
      <ul>
      <li>10/08/2023, The proceedings of CLeaR 2023 are released. PLease find the details <a href="https://are01.safelinks.protection.outlook.com/?url=https%3A%2F%2Fproceedings.mlr.press%2Fv213%2F&data=05%7C01%7CGuangyi.Chen%40mbzuai.ac.ae%7Cf00007bd8cae4c4aa62208db99b07e12%7Cc93272d31b074b3da3b619b34a973915%7C0%7C0%7C638272755550694524%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C3000%7C%7C%7C&sdata=r%2BPILmb4gAyPQB6X1o167ThRTiCFsKZR%2FUGWqjUEwOk%3D&reserved=0"> here</a></li>
      <li>05/05/2023, The table discussion results on <i>Causality in the time of LLMs</i> are released. PLease find the details <a href="https://www.cclear.cc/2023/CLeaR23_roundtable_discussion.pdf"> here</a></li>
      <li>26/04/2023, We are excited to announce that the <b>Best Paper award</b> has been presented to <i>Backtracking Counterfactuals</i> by Julius Von Kügelgen, Abdirisak Mohamed, and Sander Beckers. </li>
      <li>11/04/2023, Details about the social activities are now online (see under Program)</li>
      <li>The start and end of the conference: Registration from noon to 1pm on Tuesday Apr 11th. Conference ends 1pm Friday Apr 14th. </li>
      <li>09/03/2023, presentation instructions can be found at <a href="https://www.cclear.cc/2023/PresentationInstructions">Presentation Instructions</a>. </li>
      <li>02/03/2023, accepted papers can be found at <a href="https://www.cclear.cc/2023/Acceptedpapers">Accepted Paper List</a>. </li>
      <li>12/02/2023, CleaR 2023 conference registration is open. Please register <a HREF="https://clear.regfox.com/cclear2023registration">here</a>.  </li>
      </ul>
      </div>
      <br></br>
      <div class="HomePage__ContainerStyle">
      <h5>Important Dates</h5>
      <DatesTable array={importantDates2023} />
      </div>
    </div>
  );
};

export default CLeaR2023page;
