import React from "react";
import Title from "../../../components/Title/Title";


const CallforDatasets2023page = () => {
  return (
    <div>
      <Title>Call for Causal Datasets</Title>

     <p>We are thrilled to announce our causal benchmark dataset track as part of our CLeaR 2023 conference. High-quality, publicly available causal datasets are critical for advancing the field of causality, and we seek to provide researchers with a diverse collection of datasets that can be routinely used to test, benchmark, and improve the overall performance of causal methods and algorithms. This includes but is not limited to causal inference, causal discovery, and causal representation learning. By submitting your dataset to the CLeaR 2023 dataset track, you will not only be contributing to enabling new discoveries in the field of causality, but also empowering the real-world impact in various domains such as natural sciences, healthcare, social sciences, and economics. Together with the various research fields, we hope to have a positive impact on the future of the field, and to advance our understand of causality. We encourage researchers from all fields to submit their datasets and be part of this exciting initiative. </p>
	<h5>Submission Guideline</h5>
	
	<p>We hope to make this initiative as inclusive as possible, therefore datasets with diversity in terms of domain, complexity, and scale are all welcome to submit. Submissions should include a clear description of the underlying problem setting, data format, causal quantities of interest, as well as clear definition of evaluation metrics. Submissions are NOT required to be anonymized. All submissions should comply with the TMLR guidelines for “General Ethical Conduct” outlined <a href="https://www.jmlr.org/tmlr/ethics.html">here</a>. We note that all submissions will receive a light touch review by committee members to verify that they comply with the guidelines above and ensure the data provided will be of value to the causality community. </p>

  <p><b>Non-archival policy.</b> The dataset track at CLEAR is a non-archival venue and will not have official proceedings. Submissions to the dataset track can be subsequently or concurrently submitted to other venues, but will be hosted on a dedicated website (and indexed by Google Scholar).</p>

  <p>To submit a dataset, please send the following material to our official email (<a href="mailto:clear.datasets.2023@gmail.com">clear.datasets.2023@gmail.com</a>):</p>
	<ul>
	<li> An extended abstract paper (please follow the template described <a href="https://www.overleaf.com/read/kbtfydmbgfwf">here</a>). This should include a brief description of the dataset, which may include but not limited to:
		<ul>
		<li>background information;</li>
		<li>data collection (if real-world dataset) or generation (if synthetic dataset) details;</li>
		<li>definition of the underlying causal quantities;</li>
		<li>details of causal ground truth;</li>
		<li>definition of evaluation metrics.</li>
		</ul>
		Although there is no hard page limit, we recommend to keep it brief and encourage submissions of 4 or less pages. Further technical details can be included in the github link as detailed below .</li>
	<li>A github link to the dataset repo, which should store the download link to the dataset, and include further instructions and further technical details. The following materials are welcome to be included, but not required:</li>
		<ul>
		<li>comprehensive readme;</li>
		<li>data processing and loading scripts;</li>
		<li>evaluation scripts;</li>
		<li>baseline evaluation results.</li>
		</ul>
	</ul>

  <h5>How it works</h5>
  <p>Formal review process will not be conducted for submissions. Instead, this initiative will contain the following phases:</p>
  <ul>
	<li>Screening phase: all submissions will be first screened by internal organizers to exclude low-quality datasets.</li>
	<li>Publication phase: all submissions that passed basic screening will be published in a dedicated dataset website.</li>
	<li>Public voting phase: in the dedicated dataset website, we will provide mechanisms for all participants of CLeaR 2023 to vote on each dataset. Voting criterias and guidelines will be detailed on the dedicated website. We will then sort all submissions according to their votes, and nominate the best dataset award.</li>
	</ul>

	<h5>Key dates</h5>
	<ul>
	<li> Submission deadline: Mar 10, 2023 </li>
	<li>Acceptance Notification: Mar 27, 2023</li>
	<li>Publication on dedicated website: TBD</li>
	</ul>
    </div>
  )
}

export default CallforDatasets2023page
