export const array = [
  {
    persons: [
      {
        name: "Aapo Hyvärinen",
        href: "https://www.cs.helsinki.fi/u/ahyvarin/index.shtml",
        address: "University of Helsinki, Finland",
      },
      {
        name: "Andrea Rotnitzky",
        href: "https://www.hsph.harvard.edu/andrea-rotnitzky/",
        address: "Harvard University, USA",
      },
      {
        name: "Bernhard Schölkopf",
        href: "https://www.is.mpg.de/~bs",
        address: "Max Planck Institute for Intelligent Systems, Germany",
      },
      {
        name: "Bin Yu",
        href: "https://binyu.stat.berkeley.edu/",
        address: "UC Berkeley, USA",
      },
      {
        name: "David Heckerman",
        href: "http://heckerman.com/david/",
        address: "Amazon, USA",
      },
      {
        name: "James Robins",
        href: "https://www.hsph.harvard.edu/james-robins/",
        address: "Harvard University, USA",
      },
      {
        name: "Judea Pearl",
        href: "http://bayes.cs.ucla.edu/jp_home.html",
        address: "UCLA, USA",
      },
      {
        name: "Leon Bottou",
        href: "https://leon.bottou.org/",
        address: "Facebook AI Research, USA",
      },
    ],
  },
];

export const array1 = [
  {
    persons: [
      {
        name: "Marloes Maathuis",
        href: "https://stat.ethz.ch/~mmarloes/",
        address: "ETH Zurich, Switzerland",
      },
       {
        name: "Max Welling",
        href: "https://staff.fnwi.uva.nl/m.welling/",
        address: "University of Amsterdam, Netherlands ",
      },
       {
        name: "Peter Spirtes",
        href: "https://www.cmu.edu/dietrich/philosophy/people/faculty/spirtes.html",
        address: "Carnegie Mellon University, USA",
      },
       {
        name: "Susan Murphy",
        href: "http://people.seas.harvard.edu/~samurphy/",
        address: "Harvard University, USA",
      },
       {
        name: "Terry Sejnowski",
        href: "https://www.salk.edu/scientist/terrence-sejnowski/",
        address: "Salk Institute, USA ",
      },
       {
        name: "Thomas Richardson",
        href: "https://sites.stat.washington.edu/tsr/website/inquiry/home.php",
        address: "University of Washington, USA",
      },
       {
        name: "Victor Chernozhukov",
        href: "http://www.mit.edu/~vchern/",
        address: "Massachusetts Institute of Technology, USA",
      },
       {
        name: "Yoshua Bengio",
        href: "https://yoshuabengio.org/",
        address: "University of Montreal, Canada",
      },
     
    ],
  },
];

