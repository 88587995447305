import React from "react";
import Title from "../../../components/Title/Title";

const PresentationInstruction2023page = () => {
  return (
    <div>
      <Title>Presentation Instructions</Title>
      <h5>Instructions for orals:</h5>
      <ul>
      <li><b>Oral presentations are 17min + 3min for questions</b></li>
      <li><b>Every paper accepted as oral should also prepare and present a poster</b></li>
      </ul>
      <h5>Instructions for posters:</h5>
      <ul>
        <li><b>The poster-boards cover A0 in portrait orientation, please aim for a size between A1 and A0</b> </li>
      </ul>
      <h5>Remote presentation:</h5>
      <p><b>We highly recommend to attend the conference in-person, even more so for presenting your accepted papers.</b> </p>
      <p><b>Please contact us at <a href={`mailto:zietld@amazon.com`}>zietld@amazon.com</a> in case you cannot present (oral or poster) in-person (subject: [CleaR 2023 remote]).</b> </p>
    </div>
  )
}

export default PresentationInstruction2023page
