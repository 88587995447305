import React from "react";
import Title from "../../../components/Title/Title";
import DatesTable from "../ImportantDatesPage/components/DateTable/DatesTable";
import { importantDates2025 } from "../../../common/constants/importantDates";
import "./CLeaR2025Page.scss";

const CLeaR2025page = () => {
  return (
    <div>
      <Title>CLeaR (Causal Learning and Reasoning) 2025</Title>
      <div class="HomePage__ContainerStyle">
        <p>
        Causality is a fundamental notion in science and engineering. In the past few decades, some of the most influential developments in the study of causal discovery, causal inference, and the causal treatment of machine learning have resulted from cross-disciplinary efforts. In particular, a number of machine learning and statistical analysis techniques have been developed to tackle classical causal discovery and inference problems. On the other hand, the causal view has been shown to be able to facilitate formulating, understanding, and tackling a number of hard machine learning problems in transfer learning, reinforcement learning, and deep learning.
        </p>
        <p>
          We invite submissions to the 4th conference on Causal Learning and Reasoning (CLeaR),
          and welcome paper submissions that describe new theory, methodology, and/or applications relevant to any aspect of causal
          learning and reasoning in the fields of artificial intelligence and statistics. Submitted papers will be evaluated based
          on their novelty, technical quality, and potential impact. Experimental methods and results are expected to be reproducible,
          and authors are strongly encouraged to make code and data available.
          We also encourage submissions of proof-of-concept research that puts forward novel ideas and demonstrates potential for
          addressing problems at the intersection of causality and machine learning.
          <b>CLeaR 2025 will be held in Lausanne, Switzerland, May 7 to May 9, 2025.</b>
        </p>
        Topics of submission may include, but are not limited to:
        <ul>
          <li>Machine learning building on causal principles</li>
          <li>Causal discovery in complex environments</li>
          <li>Efficient causal discovery in large-scale datasets</li>
          <li>Causal effect identification and estimation</li>
          <li>Causal generative models for machine learning</li>
          <li>Unsupervised and semi-supervised deep learning connected to causality</li>
          <li>Machine learning with heterogeneous data sources</li>
          <li>Benchmark for causal discovery and causal reasoning</li>
          <li>Reinforcement learning</li>
          <li>Fairness, accountability, transparency, explainability, trustworthiness, and recourse</li>
          <li>Applications of any of the above to real-world problems</li>
          <li>Causal representation learning</li>
          <li>Causal inference in philosophy and psychology</li>
        </ul>
        <p> The program at the conference will highlight new applications of causality to real-world problems, benchmark datasets,
        or benchmark methodologies.</p>
      </div>
      <br></br>
      <div class="HomePage__ContainerStyle">
      <h5>Important Dates</h5>
      <DatesTable array={importantDates2025} />
      </div>
    </div>
  );
};

export default CLeaR2025page;
