import React from "react";
import Title from "../../../components/Title/Title";


const CameraReady2023page = () => {
  return (
    <div>
      <Title>Camera-ready Instructions</Title>
    <p>TBD </p> 
    
    </div>
  );
};

export default CameraReady2023page;
