const acceptedPapers = [
  {
    title: "Generalizing Clinical Trials with Convex Hulls",
    author:
      "Eric Strobl, Thomas A Lasko",
  },
  {
    title: "Backtracking Counterfactuals",
    author:
      "Julius Von Kügelgen, Abdirisak Mohamed, Sander Beckers",
  },
  {
    title: "Stochastic Causal Programming for Bounding Treatment Effects",
    author:
      "Kirtan Padh, Jakob Zeitler, David Watson, Matt Kusner, Ricardo Silva, Niki Kilbertus",
  },
  {
    title: "Distinguishing Cause from Effect on Categorical Data: The Uniform Channel Model",
    author:
      "Mario A. T. Figueiredo, Catarina Oliveira",
  },
  {
    title: "Jointly Learning Consistent Causal Abstractions Over Multiple Interventional Distributions",
    author:
      "Fabio Massimo Zennaro, Máté Drávucz, Geanina Apachitei, W. Dhammika Widanage, Theodoros Damoulas",
  },
  {
    title: "Causal Abstraction with Soft Interventions",
    author:
      "Riccardo Massidda, Atticus Geiger, Thomas Icard, Davide Bacciu",
  },
  {
    title: "Directed Graphical Models and Causal Discovery for Zero-Inflated Data",
    author:
      "Shiqing Yu, Mathias Drton, Ali Shojaie",
  },  
  {
    title: "An Algorithm and Complexity Results for Causal Unit Selection",
    author:
      "Haiying Huang, Adnan Darwiche",
  },
];

export default acceptedPapers;
