import React from "react";
import Title from "../../../components/Title/Title";

const AreaChair2022page = () => {
  return (
    <div>
      <Title>Area Chairs</Title>
     {/* <ul>
    Alexander D'Amour (Google Brain)<br />
    Anirudh Goyal (University of Montreal / Mila)<br />
    Cosma Shalizi (Carnegie Mellon University)<br />
    David Blei (Columbia University)<br />
    David Lopez-Paz (Facebook AI)<br />
    Dominik Rothenhäusler (Stanford University)<br />
    Elias Bareinboim (Columbia University)<br />
    Emilija Perković (University of Washington)<br />
    Emre Kiciman (Microsoft Research)<br />
    Francesco Locatello (Amazon AWS)<br />
    Ilya Shpitser (Johns Hopkins University)<br />
    Irina Higgins (DeepMind)<br />
    Irina Rish (University of Montreal / Mila)<br />
    Jiji Zhang (Hong Kong Baptist University)<br />
    Jonas Peters (University of Copenhagen)<br />
    Joris Mooij (University of Amsterdam)<br />
    Michel Besserve (Max Planck Institute for Intelligent Systems / Biological Cybernetics)<br />
    Michele Sebag (CNRS)<br />
    Mingming Gong (University of Melbourne)<br />
    Nathan Kallus (Cornell University)<br />
    Negar Kiyavash (EPFL)<br />
    Peter Spirtes (Carnegie Mellon University )<br />
    Shohei Shimizu (Shiga University)<br />
    Tom Claassen (Radboud University Nijmegen)<br />
    </ul>*/}
    Alexander D'Amour (Google Brain)<br />
    Anirudh Goyal (University of Montreal / Mila)<br />
    Cosma Shalizi (Carnegie Mellon University)<br />
    David Blei (Columbia University)<br />
    David Lopez-Paz (Facebook AI)<br />
    Dominik Rothenhäusler (Stanford University)<br />
    Elias Bareinboim (Columbia University)<br />
    Emilija Perković (University of Washington)<br />
    Emre Kiciman (Microsoft Research)<br />
    Francesco Locatello (Amazon AWS)<br />
    Ilya Shpitser (Johns Hopkins University)<br />
    Irina Higgins (DeepMind)<br />
    Irina Rish (University of Montreal / Mila)<br />
    Jiji Zhang (Hong Kong Baptist University)<br />
    Jonas Peters (University of Copenhagen)<br />
    Joris Mooij (University of Amsterdam)<br />
    Michel Besserve (Max Planck Institute for Intelligent Systems / Biological Cybernetics)<br />
    Michele Sebag (CNRS)<br />
    Mingming Gong (University of Melbourne)<br />
    Nathan Kallus (Cornell University)<br />
    Negar Kiyavash (EPFL)<br />
    Peter Spirtes (Carnegie Mellon University )<br />
    Shohei Shimizu (Shiga University)<br />
    Tom Claassen (Radboud University Nijmegen)<br />
    <br />
    </div>
  )
}

export default AreaChair2022page
