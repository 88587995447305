import React from "react";
import Title from "../../../components/Title/Title";

const StudentVolunteers2023page = () => {
  return (
    <div>
      <Title>Student Volunteers</Title>
      <p> TBD </p>
    </div>
  )
}

export default StudentVolunteers2023page
