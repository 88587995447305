import React from "react";
import ShowMoreText from 'react-show-more-text';
import "./PaperTable.scss";

const PaperTable = ({ array }) => {
  return (
    <div className="table PaperTable">
      {array.map(({title, author ,description}) => (
        <tr>
          <td>
            <h4 className="PaperTable__title">{title}</h4>
            {author}
            <ShowMoreText lines={1}>{description}</ShowMoreText>
          </td>
        </tr>
      ))}
    </div>
  );
};

export default PaperTable;
