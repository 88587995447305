import React from 'react'
import Title from '../../../components/Title/Title'
import OCTable from './components/OCTable/OCTable'
import { array2023 } from '../../../common/constants/organizingCommittee'


const OrganizingCommittee2023page = () => {
  return (
    <div>
      <Title>Organizing Committee</Title>
      <OCTable array={array2023} />
    </div>
  )
}

export default OrganizingCommittee2023page
