import React from "react";
import Title from "../../../components/Title/Title";

const StudentVolunteers2023page = () => {
  return (
    <div>
      <Title>Student Volunteers</Title>
      Danru Xu (University of Amsterdam) <br />
      Mátyás Schubert (University of Amsterdam) <br />
      Philip Boeken (University of Amsterdam) <br />
      Teodora Pandeva (University of Amsterdam) <br />
      Daan Roos (University of Amsterdam) <br />
      Fan Feng (City University of Hong Kong)
    </div>
  )
}

export default StudentVolunteers2023page
