const acceptedPapers = [
  {
    title: "A Distance Covariance-based Kernel for Nonlinear Causal Clustering in Heterogeneous Populations",
    author:
      "Alex Markham (KTH Royal Institute of Technology), Richeek Das (Indian Institute of Technology), Moritz Grosse-Wentrup (University of Vienna)",
  },  
  {
    title: "A Multivariate Causal Discovery based on Post-Nonlinear Model",
    author:
      "Kento Uemura (RIKEN), Takuya Takagi (Fujitsu Ltd.), Takayuki Kambayashi, Hiroyuki Yoshida, Shohei Shimizu (Shiga University)",
  },
  {
    title: "A Uniformly Consistent Estimator of non-Gaussian Causal Effects Under the $k$-Triangle-Faithfulness Assumption",
    author:
      "Shuyan Wang (Carnegie Mellon University), Peter Spirtes (Carnegie Mellon University)",
  },
  {
    title: "Amortized Causal Discovery: Learning to Infer Causal Graphs from Time-Series Data",
    author:
      "Sindy Löwe (University of Amsterdam), David Madras (University of Toronto), Richard Zemel (Columbia University), Max Welling (University of Amsterdam)",
  }, 
  {
    title: "Attainability and Optimality: The Equalized Odds Fairness Revisited",
    author:
      "Zeyu Tang (Carnegie Mellon University), Kun Zhang (Carnegie Mellon University)",
  },
  {
    title: "Bivariate Causal Discovery via Conditional Divergence",
    author:
      "Bao Duong (Deakin University), Thin Nguyen (Deakin University)",
  },  
  {
    title: "Can Humans Be out of the Loop?",
    author:
      "Junzhe Zhang (Columbia University), Elias Bareinboim (Columbia University)",
  },
  {
    title: "Causal Bandits without prior knowledge using separating sets",
    author:
      "Arnoud De Kroon (University of Amsterdam), Joris Mooij (University of Amsterdam), Danielle Belgrave (DeepMind)",
  },
  {
    title: "Causal Discovery for Linear Mixed Data",
    author:
      "Yan Zeng (Tsinghua University), Shohei Shimizu (Shiga University), Hidetoshi Matsui (Kyushu University), Fuchun Sun (Tsinghua University)",
  },  
  {
    title: "Causal Discovery in Linear Structural Causal Models with Deterministic Relations",
    author:
      "Yuqin Yang (Georgia Institute of Technology), Mohamed S Nafea (University of Detroit Mercy), AmirEmad Ghassami (Johns Hopkins University), Negar Kiyavash (Swiss Federal Institute of Technology Lausanne)",
  },
  {
    title: "Causal Imputation via Synthetic Interventions",
    author:
      "Chandler Squires (MIT), Dennis Shen (MIT), Anish Agarwal (MIT), Devavrat Shah (MIT), Caroline Uhler (MIT)",
  },
  {
    title: "Causal Markov Decision Processes: Learning Good Interventions Efficiently",
    author:
      "Yangyi Lu (University of Michigan), Amirhossein Meisami (University of Michigan), Ambuj Tewari (University of Michigan)",
  },  
  {
    title: "Causal Structure Discovery between Clusters of Nodes Induced by Latent Factors",
    author:
      "Chandler Squires (Massachusetts Institute of Technology), Annie Yun (MIT), Eshaan Nichani (Princeton University), Raj Agrawal (MIT), Caroline Uhler (MIT)",
  },
  {
    title: "CausalCity: Complex Simulations with Agency for Causal Discovery and Reasoning",
    author:
      "Daniel McDuff (Microsoft), Yale Song (Microsoft), Jiyoung Lee (Yonsei university), Vibhav Vineet (Microsoft), Sai Vemprala (Microsoft), Nicholas Alexander Gyde (University of Washington), Hadi Salman (Massachusetts Institute of Technology), Shuang Ma (Microsoft), Kwanghoon Sohn (Yonsei University), Ashish Kapoor (Microsoft)",
  },
  {
    title: "Data-driven exclusion criteria for instrumental variable studies",
    author:
      "Tony Liu (University of Pennsylvania), Patrick Lawlor, Lyle Ungar (University of Pennsylvania), Konrad Kording (University of Pennsylvania)",
  },
  {
    title: "Differentiable Causal Discovery Under Latent Interventions",
    author:
      "Gonçalo Rui Alves Faria (Instituto Superior Técnico), Andre Martins (Instituto Superior Técnico), Mario A. T. Figueiredo (Instituto Superior Técnico)",
  },
  {
    title: "Diffusion Causal Models for Counterfactual Estimation",
    author:
      "Pedro Sanchez (University of Edinburgh), Sotirios A. Tsaftaris (University of Edinburgh)",
  },  
  {
    title: "Disentanglement via Mechanism Sparsity Regularization: A New Principle for Nonlinear ICA",
    author:
      "Sebastien Lachapelle (University of Montreal), Pau Rodriguez (Element AI), Rémi LE PRIOL (University of Montreal), Yash Sharma (University of Tuebinge), Katie E Everett (Massachusetts Institute of Technology), Alexandre Lacoste (Element AI), Simon Lacoste-Julien (University of Montreal)",
  },
  {
    title: "Disentangling Controlled Effects for Hierarchical Reinforcement Learning",
    author:
      "Oriol Corcoll Andreu (University of Tartu), Raul Vicente (University of Tartu)",
  },    
  {
    title: "Equality Constraints in Linear Hawkes Processes",
    author:
      "Søren Wengel Mogensen (Lund University / Lund Institute of Technology)",
  },
  {
    title: "Estimating Social Influence from Observational Data",
    author:
      "Dhanya Sridhar (Columbia University), Caterina De Bacco (Max-Planck Institute), David Blei (Columbia University)",
  },
  {
    title: "Fair Classification with Instance-dependent Label Noise",
    author:
      "Songhua Wu (University of Sydney ), Mingming Gong (University of Melbourne), Bo Han (HKBU), Yang Liu (University of California, Santa Cru), Tongliang Liu (University of Sydney )",
  },  
  {
    title: "Identifying cause versus effect in time series via spectral independence: theoretical foundations",
    author:
      "Michel Besserve (MPI for Intelligent Systems), Naji Shajarisales (Carnegie Mellon University), Dominik Janzing (Amazon), Bernhard Schölkopf (Max Planck Institute for Intelligent Systems)",
  },
  {
    title: "Identifying Coarse-grained Independent Causal Mechanisms with Self-supervision",
    author:
      "Xiaoyang Wang (University of Illinois, Urbana Champaign), Klara Nahrstedt (UIUC), Oluwasanmi O Koyejo (UIUC)",
  },
  {
    title: "Identifying Principal Stratum Causal Effects Conditional on a Post-treatment Intermediate Response",
    author:
      "Xiaoqing Tan (University of Pittsburgh), Judah Abberbock, Priya Rastogi (University of Pittsburgh), Gong Tang (University of Pittsburgh)",
  },
  {
    title: "Info Intervention and its Causal Calculus",
    author:
      "Gong Heyang (University of Science and Technology of China)",
  },    
  {
    title: "Integrative $R$-learner of heterogeneous treatment effects combining experimental and observational studies",
    author:
      "Lili Wu (North Carolina State University), Shu Yang (North Carolina State University)",
  },
  {
    title: "Learning Invariant Representations with Missing Data",
    author:
      "Mark Goldstein (New York University), Joern-Henrik Jacobsen (Vector Institute), Olina Chau (Stanford University), Adriel Saporta (Stanford University), Aahlad Manas Puli (New York University), Rajesh Ranganath (New York University), Andrew Miller (Columbia University)",
  },
  {
    title: "Local Constraint-Based Causal Discovery under Selection Bias",
    author:
      "Philip Versteeg (University of Amsterdam), Joris Mooij (University of Amsterdam), Cheng Zhang (Microsoft)",
  },
  {
    title: "On the Equivalence of Causal Models",
    author:
      "Jun Otsuka (Kyoto University), Hayato Saigo",
  },      
  {
    title: "Partial Identification with Noisy Covariates: A Robust Optimization Approach",
    author:
      "Wenshuo Guo (University of California Berkeley), Mingzhang Yin (Columbia University), Yixin Wang (University of Michigan), Michael Jordan (University of California Berkeley)",
  },
  {
    title: "Predictive State Propensity Subclassification: A causal inference algorithm for optimal data-driven propensity score stratification",
    author:
      "Joseph Kelly (Google), Jing Kong (Google), Georg M. Goerg (EvolutionIQ)",
  },  
  {
    title: "Process Independence Testing in Proximal Graphical Event Models",
    author:
      "Debarun Bhattacharjya (IBM), Karthikeyan Shanmugam (IBM), Tian Gao (IBM), Dharmashankar Subramanian (IBM)",
  },  
  {
    title: "Relational Causal Models with Cycles: Representation and Reasoning",
    author:
      "Ragib Ahsan (University of Illinois, Chicago), David Arbour (Adobe Systems), Elena Zheleva (University of Illinois, Chicago)",
  },
  {
    title: "Same Cause; Different Effects in the Brain",
    author:
      "Mariya Toneva (Carnegie Mellon University), Jennifer Williams (Carnegie Mellon University), Anand Bollu (Carnegie Mellon University), Leila Wehbe (Carnegie Mellon University)",
  },
  {
    title: "Simple data balancing achieves competitive worst-group-accuracy",
    author:
      "Badr Youbi Idrissi (Facebook), Martin Arjovsky (New York University), Mohammad Pezeshki (University of Montrea), David Lopez-Paz (Facebook)",
  },  
  {
    title: "Some Reflections on Drawing Causal Inference using Textual Data: Parallels Between Human Subjects and Organized Texts",
    author:
      "Bo Zhang (University of Pennsylvania), Jiayao Zhang (University of Pennsylvania)",
  },
  {
    title: "Towards efficient representation identification in supervised learning",
    author:
      "Kartik Ahuja (Montreal Institute for Learning Algorithms), Divyat Mahajan (Montreal Institute for Learning Algorithms), Vasilis Syrgkanis (Microsoft), Ioannis Mitliagkas (University of Montreal)",
  },
  {
    title: "VIM: Variational Independent Modules for Video Prediction",
    author:
      "Rim Assouel (University of Montreal), Lluis Castrejon (University of Montreal), Aaron Courville (University of Montreal), Nicolas Ballas (Facebook), Yoshua Bengio (University of Montreal)",
  },
  {
    title: "Weakly Supervised Discovery of Semantic Attributes",
    author:
      "Ameen Ali Ali (Tel Aviv University), Tomer Galanti (DeepMind), Evgenii Zheltonozhskii (Technion), Chaim Baskin (Technion), Lior Wolf (Tel Aviv University)",
  },
];

export default acceptedPapers;
