import React from "react";
import Title from "../../../components/Title/Title";
import DatesTable from "../ImportantDatesPage/components/DateTable/DatesTable";
import { importantDates2022 } from "../../../common/constants/importantDates";
import "./CLeaR2022Page.scss";

const CLeaR2022page = () => {
  return (
    <div>
      <Title>CLeaR (Causal Learning and Reasoning) 2022</Title>
      <div class="HomePage__ContainerStyle">
        <p>
          Causality is a fundamental notion in science and engineering. In the
          past few decades, some of the most influential developments in the
          study of causal discovery, causal inference, and the causal treatment
          of machine learning have resulted from cross-disciplinary efforts. In
          particular, a number of machine learning and statistical analysis
          techniques have been developed to tackle classical causal discovery
          and inference problems. On the other hand, the causal view has been
          shown to be able to facilitate formulating, understanding, and
          tackling a number of hard machine learning problems in transfer
          learning, reinforcement learning, and deep learning.
        </p>
        <p>
          We invite submissions to the 1st conference on Causal Learning and
          Reasoning (CLeaR), and welcome paper submissions that describe new
          theory, methodology, and/or applications relevant to any aspect of
          causal learning and reasoning in the fields of artificial intelligence
          and statistics. Submitted papers will be evaluated based on their
          novelty, technical quality, and potential impact. Experimental methods
          and results are expected to be reproducible, and authors are strongly
          encouraged to make code and data available. We also encourage
          submissions of proof-of-concept research that puts forward novel ideas
          and demonstrates potential for addressing problems at the intersection
          of causality and machine learning. <b>CLeaR 2022 will be held in Eureka, CA, USA from April 11 to 13, 2022, with virtual elements. </b>
        </p>
        Topics of submission may include, but are not limited to:
        <ul>
        <li>Machine learning building on causal principles</li>
        <li>Causal discovery in complex environments</li>
        <li>Efficient causal discovery in large-scale datasets</li>
        <li>Causal effect identification and estimation</li>
        <li>Causal generative models for machine learning</li>
        <li>
        Unsupervised and semi-supervised deep learning connected to causality
        </li>
        <li>Machine learning with heterogeneous data sources</li>
        <li>Benchmark for causal discovery and causal reasoning</li>
        <li>Reinforcement learning</li>
        <li>
          Fairness, accountability, transparency, explainability,
          trustworthiness, and recourse{" "}
        </li>
        <li>Applications of any of the above to real-world problems</li>
        </ul>
          <p>Submit at <a href="https://openreview.net/group?id=cclear.cc/CLeaR/2022/Conference">https://openreview.net/group?id=cclear.cc/CLeaR/2022/Conference</a>.</p> 
      </div>
      <br></br>
      <div>
      <h5> Updates </h5>
      <ul>
      <li>12/03/2022, CleaR 2022 conference registration is open. Please register <a HREF="">here</a> (registration closed).  </li>
      <li>07/03/2022, CleaR 2022 conference schedule is available. </li>
      <li>10/02/2022, cameray-ready instructions can be found at <a href="https://www.cclear.cc/2022/CameraReady">Camera-Ready Instrucutions</a>. </li>
      <li>13/01/2022, accepted papers can be found at <a href="https://www.cclear.cc/2022/Acceptedpapers">Accepted Paper List</a>. </li>
      </ul>
      </div>
      <br></br>
      <div class="HomePage__ContainerStyle">
      <h5>Important Dates</h5>
      <DatesTable array={importantDates2022} />
      </div>
    </div>
  );
};

export default CLeaR2022page;
