import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import './NavItem.scss';

const NavItem = ({children}) => {
  const year = useLocation().pathname.split('/')[1]
  return (
    <li className="nav-item" >
      <NavLink className="nav-link" to={`/${year + '/'+ children.split(' ').join('')}`}>{children}</NavLink>
    </li>
  )
}

export default NavItem
