import React from "react";
import Title from "../../../components/Title/Title";

const FullAgenda2025page = () => {
  return (
    <div>
      <Title>Full Agenda</Title>
      <p> TBA </p>
    </div>
  )
}

export default FullAgenda2025page
