import React from "react";
import "./OCTable.scss";

const OCTable = ({ array }) => {
  return (
    <table className="table OCTable">
      {array.map(({ position, persons }) => (
        <tr>
          <td>
            <h2 className="OCTable__position">{position}</h2>
            {persons.map(({ name, href, address }) => (
              <div className="OCTable__person">
                <a className="OCTable__person--name" href={href}>
                  {name}
                </a>
                <br />
                <span className="OCTable__address">{address}</span>
              </div>
            ))}
          </td>
        </tr>
      ))}
    </table>
  );
};

export default OCTable;
