const acceptedPapers = [
  {
    title: "Sample-Specific Root Causal Inference with Latent Variables",
    author: "Eric Strobl, Thomas A Lasko",
    },
    {
    title: "Causal Discovery for Non-stationary Non-linear Time Series Data Using Just-In-Time Modeling",
    author: "Daigo Fujiwara, Kazuki Koyama, Keisuke Kiritoshi, Tomomi Okawachi, Tomonori Izumitani, Shohei Shimizu",
    },
    {
    title: "Causal Models with Constraints",
    author: "Sander Beckers, Joseph Halpern, Christopher Hitchcock",
    },
    {
    title: "Non-parametric identifiability and sensitivity analysis of synthetic control models",
    author: "Jakob Zeitler, Athanasios Vlontzos, Ciarán Mark Gilligan-Lee",
    },
    {
    title: "Factorization of the Partial Covariance in Singly-Connected Path Diagrams",
    author: "Jose Peña",
    },
    {
    title: "Estimating long-term causal effects from short-term experiments and long-term observational data with unobserved confounding",
    author: "Graham Van Goffrier, Lucas Maystre, Ciarán Mark Gilligan-Lee",
    },
    {
    title: "Local Dependence Graphs for Discrete Time Processes",
    author: "Wojciech Niemiro, Łukasz Rajkowski",
    },
    {
    title: "Scalable Causal Discovery with Score Matching",
    author: "Francesco Montagna, Nicoletta Noceti, Lorenzo Rosasco, Kun Zhang, Francesco Locatello",
    },
    {
    title: "Causal Discovery with Score Matching on Additive Models with Arbitrary Noise",
    author: "Francesco Montagna, Nicoletta Noceti, Lorenzo Rosasco, Kun Zhang, Francesco Locatello",
    },
    {
    title: "Beyond the Markov Equivalence Class: Extending Causal Discovery under Latent Confounding",
    author: "Mirthe Maria Van Diepen, Ioan Gabriel Bucur, Tom Heskes, Tom Claassen",
    },
    {
    title: "Learning Conditional Granger Causal Temporal Networks",
    author: "Ananth Balashankar, Srikanth Jagabathula, Lakshmi Subramanian",
    },
    {
    title: "Learning Causal Representations of Single Cells via Sparse Mechanism Shift Modeling",
    author: "Romain Lopez, Natasa Tagasovska, Stephen Ra, Kyunghyun Cho, Jonathan Pritchard, Aviv Regev",
    },
    {
    title: "Branch-Price-and-Cut for Causal Discovery",
    author: "James Cussens",
    },
    {
    title: "Instrumental Processes Using Integrated Covariances",
    author: "Søren Wengel Mogensen",
    },
    {
    title: "A Meta-Reinforcement Learning Algorithm for Causal Discovery",
    author: "Andreas W.M. Sauter, Erman Acar, Vincent Francois-Lavet",
    },
    {
    title: "Causal Inference Despite Limited Global Confounding via Mixture Models",
    author: "Spencer L. Gordon, Bijan Mazaheri, Yuval Rabani, Leonard Schulman",
    },
    {
    title: "Causal Triplet: An Open Challenge for Intervention-centric Causal Representation Learning",
    author: "Yuejiang Liu, Alexandre Alahi, Chris Russell, Max Horn, Dominik Zietlow, Bernhard Schölkopf, Francesco Locatello",
    },
    {
    title: "Image-based Treatment Effect Heterogeneity",
    author: "Connor Thomas Jerzak, Fredrik Daniel Johansson, Adel Daoud",
    },
    {
    title: "Causal Learning through Deliberate Undersampling",
    author: "Kseniya Solovyeva, David Danks, Mohammadsajad Abavisani, Sergey Plis",
    },
    {
    title: "Influence-Aware Attention for Multivariate Temporal Point Processes",
    author: "Xiao Shou, Tian Gao, Dharmashankar Subramanian, Debarun Bhattacharjya, Kristin Bennett",
    },
    {
    title: "Evaluating Temporal Observation-Based Causal Discovery Techniques Applied to Road Driver Behaviour",
    author: "Rhys Peter Matthew Howard, Lars Kunze",
    },
    {
    title: "On Discovery of Local Independence over Continuous Variables via Neural Contextual Decomposition",
    author: "Inwoo Hwang, Yunhyeok Kwak, Yeon-Ji Song, Byoung-Tak Zhang, Sanghack Lee",
    },
    {
    title: "Local Causal Discovery for Estimating Causal Effects",
    author: "Shantanu Gupta, David Childers, Zachary Chase Lipton",
    },
    {
    title: "Can Active Sampling Reduce Causal Confusion in Offline Reinforcement Learning?",
    author: "Gunshi Gupta, Tim G. J. Rudner, Rowan Thomas McAllister, Adrien Gaidon, Yarin Gal",
    },
    {
    title: "Causal Inference with Non-IID Data under Model Uncertainty",
    author: "Chi Zhang, Karthika Mohan, Judea Pearl",
    },
    {
    title: "Factual Observation Based Heterogeneity Learning for Counterfactual Prediction",
    author: "Hao Zou, Haotian Wang, Renzhe Xu, Bo Li, Jian Pei, Ye Jun Jian, Peng Cui",
    },
    {
    title: "On the Interventional Kullback-Leibler Divergence",
    author: "Jonas Bernhard Wildberger, Siyuan Guo, Arnab Bhattacharyya, Bernhard Schölkopf",
    },
    {
    title: "Unsupervised Object Learning via Common Fate",
    author: "Matthias Tangemann, Steffen Schneider, Julius Von Kügelgen, Francesco Locatello, Peter Vincent Gehler, Thomas Brox, Matthias Kuemmerer, Matthias Bethge, Bernhard Schölkopf",
    },
    {
    title: "Practical Algorithms for Orientations of Partially Directed Graphical Models",
    author: "Malte Luttermann, Marcel Wienöbst, Maciej Liskiewicz",
    },
    {
    title: "Enhancing Causal Discovery from Robot Sensor Data in Dynamic Scenarios",
    author: "Luca Castri, Sariah Mghames, Marc Hanheide, Nicola Bellotto",
    },
    {
    title: "Leveraging Causal Graphs for Blocking in Randomized Experiments",
    author: "Abhishek Kumar Umrawal",
    },
];

export default acceptedPapers;
