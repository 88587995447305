import React from "react";
import Title from "../../../components/Title/Title";

const AreaChair2025page = () => {
  return (
    <div>
      <Title>Area Chairs</Title>
        Bo Han (Hong Kong Baptist University)<br />
        David Danks (University of California, San Diego)<br />
        Elina Robeva (University of British Columbia)<br />
        Emre Kıcıman (Microsoft Research)<br />
        Francesco Locatello (Institute of Science and Technology Austria)<br />
        Jiji Zhang (Chinese University of Hong Kong)<br />
        Kun Zhang (Carnegie Mellon University)<br />
        Linbo Wang (University of Toronto)<br />
        Mingming Gong (University of Melbourne)<br />
        Niels Richard Hansen (University of Copenhagen)<br />
        Niki Kilbertus (Technical University of Munich)<br />
        Peter Spirtes (Carginie Mellon University)<br />
        Qingyuan Zhao (University of Cambridge)<br />
        Razieh Nabi (Emory University)<br />
        Ricardo Silva (University College London)<br />
        Sara Magliacane (University of Amsterdam)<br />
        Shohei Shimizu (Shiga University)<br />
        Sofia Triantafillou (University of Crete)<br />
        Tom Claassen (Radboud University Nijmegen)<br />
        Tongliang Liu (University of Sydney)<br />
    </div>
  )
}

export default AreaChair2025page
