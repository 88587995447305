export const importantDates2022 = [
  { name: "Paper submission deadline", date: " Oct 25, 2021 11:59pm (Anywhere on Earth, AoE)" },
  { name: "Reviews released", date: " Nov 26, 2021 " },
  { name: "Author rebuttals due", date: " Dec 3, 2021 11:59pm (AoE)" },
  { name: "Final decisions", date: "  Jan 12, 2022 " },
  { name: "Camera ready deadline", date: "  Feb 20, 2022 11:59pm (AoE)" },
  { name: "Main conference", date: "Apr 11 - Apr 13, 2022" },
];

export const importantDates2023 = [
  { name: "Paper submission deadline", date: " Oct 28, 2022 11:59pm (Anywhere on Earth, AoE)" },
  { name: "Reviews released", date: " Dec 2, 2022 " },
  { name: "Author rebuttals due", date: " Dec 9, 2022 11:59pm (AoE)" },
  { name: "Final decisions", date: "  Jan 12, 2023 " },
  { name: "Camera ready deadline", date: "  Feb 20, 2023 11:59pm (AoE)" },
  { name: "Main conference", date: "Apr 11 - Apr 14 2023" },
  { name: "Final Camera ready deadline", date: "  May 26, 2023 11:59pm (AoE)" },
];

export const importantDates2024 = [
  { name: "Paper submission deadline", date: " Oct 27, 2023 11:59pm (Anywhere on Earth, AoE)" },
  { name: "Reviews released", date: " Dec 1, 2023 " },
  { name: "Author rebuttals due", date: " Dec 8, 2023 11:59pm (AoE)" },
  { name: "Final decisions", date: "  Jan 12, 2024 " },
  { name: "Camera ready deadline", date: "  Feb 20, 2024 11:59pm (AoE)" },
  { name: "Main conference", date: "Apr 1 - Apr 3 2024" },,
];

export const importantDates2025 = [
  { name: "Paper submission deadline", date: " Nov 4, 2024, 11:59pm (Anywhere on Earth, AoE)" },
  { name: "Reviews released", date: " Dec 13, 2024 " },
  { name: "Author rebuttals due", date: " Dec 20, 2024 11:59pm (AoE)" },
  { name: "Final decisions", date: "  Jan 27, 2025 " },
  { name: "Camera ready deadline", date: "   Mar 9, 2025 11:59pm (AoE)" },
  { name: "Main conference", date: "May 7 (Wed) - 9 (Fri), 2025." },,
];
