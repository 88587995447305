const acceptedPapers = [
  {
    title: "Causal Explanations and XAI",
    author:
      "Sander Beckers (University of Tuebingen)",
  },
  {
    title: "Differentially Private Estimation of Heterogeneous Causal Effects",
    author:
      "Fengshi Niu (Stanford University), Harsha Nori (Microsoft), Brian Quistorff (University of Maryland, College Park), Rich Caruana (Carnegie Mellon University), Donald Ngwe (Microsoft), Aadharsh Kannan (Microsoft)",
  },
  {
    title: "Evidence-Based Policy Learning",
    author:
      "Jann Spiess (Stanford University), Vasilis Syrgkanis (Microsoft)",
  },
  {
    title: "Interactive rank testing by betting",
    author:
      "Boyan Duan (Carnegie Mellon University), Aaditya Ramdas (Carnegie Mellon University), Larry Wasserman (Carnegie Mellon University)",
  },
  {
    title: "Learning Causal Overhypotheses through Exploration in Children and Computational Models",
    author:
      "Eliza Kosoy (University of California Berkeley), Jasmine L Collins (University of California Berkeley), David Chan (University of California Berkeley), Jessica B Hamrick (DeepMind), Nan Rosemary Ke (DeepMind), Sandy Huang (DeepMind), adrian liu (University of California, Berkeley), John Canny (University of California, Berkeley), Alison Gopnik (University of California, Berkeley)",
  },
  {
    title: "Non-parametric Inference Adaptive to Intrinsic Dimension",
    author:
      "Khashayar Khosravi (Google), Greg Lewis (Microsoft), Vasilis Syrgkanis (Microsoft)",
  },
  {
    title: "Optimal Training of Fair Predictive Models",
    author:
      "Razieh Nabi (Emory University), Daniel Malinsky (Columbia University), Ilya Shpitser (Johns Hopkins University)",
  },  
  {
    title: "Selection, Ignorability and Challenges With Causal Fairness",
    author:
      "Jake Fawkes (University of Oxford), Robin Evans (University of Oxford), Dino Sejdinovic (University of Oxford)",
  },
  {
    title: "Typing assumptions improve identification in causal discovery",
    author:
      "PHILIPPE BROUILLARD (University of Montreal), Perouz Taslakian (Samsung), Alexandre Lacoste (Element AI), Sebastien Lachapelle (University of Montreal), Alexandre Drouin (Laval University)",
  },
];

export default acceptedPapers;
