import React from "react";
import Title from "../../../components/Title/Title";

const ProgramCommittee2022page = () => {
  return (
    <div>
      <Title>Program Committee</Title>
      
    Adith Swaminathan (Microsoft)<br />
    Alessio Moneta (Scuola Superiore Sant'Anna Pisa)<br />
    Allison Koenecke (Microsoft)<br />
    Amit Sharma (Microsoft Research)<br />
    AmirEmad Ghassami (Johns Hopkins University)<br />
    Andrew Ying (University of Pennsylvania)<br />
    Angela Zhou (University of California Berkeley)<br />
    Anne Helby Petersen (University of Copenhagen)<br />
    Arjen Hommersom (Open University of the Netherlands)<br />
    Badr Youbi Idrissi (Ecole Normale Superieure)<br />
    Bo Han (HKBU)<br />
    Bryon Aragam (University of Chicago)<br />
    Bryan Andrews (Carnegie Mellon University)<br />
    Caleb H Miles (Columbia University)<br />
    Carlos Cinelli (University of Washington)<br />
    Christopher John Quinn (Iowa State University)<br />
    Clark Glymour (Carnegie Mellon University)<br />
    Chunchen Liu (Alibaba Group)<br />
    Conor Mayo-Wilson (University of Washington)<br />
    Daniel Chicharro (City University of Hong Kong)<br />
    David Danks (University of California, San Diego)<br />
    David Jensen (University of Massachusetts, Amherst)<br />
    Dinghuai Zhang (Montreal Institute for Learning Algorithms)<br />
    Divyansh Kaushik (Carnegie Mellon University)<br />
    Erich Kummerfeld (University of Minnesota)<br />
    Feng Xie (Peking University)<br />
    Frederick Eberhardt (California Institute of Technology)<br />
    Fredrik Daniel Johansson (Chalmers University of Technology)<br />
    Gregory F Cooper (University of Pittsburgh)<br />
    Ignavier Ng (Carnegie Mellon University)<br />
    Ioan Gabriel Bucur (Radboud University Nijmegen)<br />
    Jacob Dorn (Princeton University)<br />
    Jakob Runge (TU Berlin)<br />
    Jalal Etesami (Swiss Federal Institute of Technology Lausanne)<br />
    Jaron J.R. Lee (Johns Hopkins University)<br />
    Jinzhou Li (Swiss Federal Institute of Technology)<br />
    Juan D. Correa (Universidad Aut´ónoma de Manizales)<br />
    Johannes Textor (Radboud University )<br />
    Jose Peña (Linköping University)<br />
    Junzhe Zhang(Columbia University)<br />
    Kartik Ahuja (Montreal Institute for Learning Algorithms)<br />
    Karthika Mohan (Oregon State University)<br />
    Kayvan Sadeghi (University College London)<br />
    Kelly Wang Zhang (Harvard University)<br />
    Konstantin Genin (University of Tuebingen)<br />
    Kun Kuang (Zhejiang University)<br />
    Linbo Wang (Toronto University)<br />
    Lin Liu (Shanghai Jiao Tong University)<br />
    Marco Zaffalon (IDSIA)<br />
    Martin Arjovsky (New York University)<br />
    Markus Kalisch (Swiss Federal Institute of Technology)<br />
    Maxime Peyrard (Swiss Federal Institute of Technology Lausanne)<br />
    Maxime Gasse (MILA research institute)<br />
    Menghan Wang (eBay)<br />
    Melih Barsbey (Bogazici University)<br />
    Mingzhang Yin (Columbia University)<br />
    Marc-André Legault (McGill University)<br />
    Murat Kocaoglu (Purdue University)<br />
    Nicolas Usunier (Facebook)<br />
    Nikolaj Thams (University of Copenhagen)<br />
    Noam Finkelstein (Johns Hopkins University)<br />
    Numair Sani (Johns Hopkins University)<br />
    Olivier Goudet (University of Angers )<br />
    Paul Rolland (Swiss Federal Institute of Technology Lausanne)<br />
    Peng Cui (Tsinghua University)<br />
    Petar Stojanov (MIT)<br />
    Phillip Lippe (University of Amsterdam)<br />
    Philippe Brouillard (University of Montreal)<br />
    Ranjani Srinivasan (Johns Hopkins University)<br />
    Reda ALAMI (Paris Saclay University)<br />
    Richard Guo (University of Washington)<br />
    Ruibo Tu (KTH)<br />
    Ruocheng Guo (City University of Hong Kong)<br />
    Ruben Sanchez Romero (Rutgers University)<br />
    Rohit Bhattacharya (Williams College)<br />
    Santacatterina Michele (NYU Langone Health)<br />
    Sara Magliacane (University of Amsterdam)<br />
    Sanghack Lee (Seoul National University)<br />
    Sebastien Lachapelle (University of Montreal)<br />
    Shengyu Zhu (Huawei Noah's Ark Lab)<br />
    Shuyu Dong (Inria)<br />
    Sindy Löwe (University of Amsterdam)<br />
    Sisi Ma (University of Minnesota - Twin Cities)<br />
    Takashi Nicholas Maeda (RIKEN)<br />
    Tongliang Liu (University of Sydney)<br />
    Thomas Icard (Stanford University)<br />
    Weijia Zhang (Southeast University)<br />
    Wei Huang (University of Melbourne)<br />
    Weiran Yao (Carnegie Mellon University)<br />
    Wolfgang Wiedermann (University of Missouri-Columbia)<br />
    Xiaojie Mao (Tsinghua University)<br />
    Yi Su (University of California Berkeley)<br />
    Yixin Wang (University of Michigan)<br />
    Yan Zeng (Tsinghua University)<br />
    Yue Liu (Renmin University of China)<br />
    Yuhao Wang (Tsinghua University)<br />
    Y. Samuel Wang (Cornell University)<br />
    Zhuangyan Fang (Peking University)<br />
    <br />
    </div>
  )
}

export default ProgramCommittee2022page
