import React from "react";
import Title from "../../../components/Title/Title";


const CameraReady2022page = () => {
  return (
    <div>
      <Title>Camera-ready Instructions</Title>
    <p>The deadline for submitting your camera-ready version is Feb. 20, 2022. The camera-ready version of your paper may have one extra page, in order to address the review comments. That is, it may have 13 single-column PMLR-formatted pages, plus unlimited additional pages for references and appendices. Appendices should be included in the same pdf after the references section. Please use \documentclass[final,12pt] {'{'}clear2022{'}'} when preparing the camera-ready version. Please don’t include any packages like “geometry” or “full page” to change the margins. For consistency across the proceedings, ensure your paper is formatted for letter paper rather than A4 (you can check with pdfinfo). </p> 
    
    </div>
  );
};

export default CameraReady2022page;
