import React from "react";
import "./DatesTable.scss";

const DatesTable = ({array}) => {
  return (
    <table className="table DatesTable">
      <thead>
        <tr>
          <th width="60%">Name</th>
          <th>Date</th>
        </tr>
      </thead>
      <tbody>
        {array.map(({ name, date }) => (
          <tr>
            <td>{name}</td>
            <td>{date}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default DatesTable;
