import React from "react";
import Title from "../../../components/Title/Title";

const KeynoteSpeaker2023page = () => {
    return (
        <div>
            <Title>Plenary Speakers</Title>
            <table>

            <tr>- <a href="https://www.cs.helsinki.fi/u/ahyvarin/">Aapo Hyvarinen</a>, University of Helsinki</tr>
            <tr><b>Title:</b> Causal discovery and latent-variable models </tr>
            <tr><p><b>Abstract:</b> There is a deep connection between causal discovery and latent-variable models such as factor analysis, independent component analysis, and various unsupervised deep learning models. In several cases, estimation of a latent-variable model enables causal discovery, due to a kind of equivalence of the two in a purely observational regime. The key concept here is identifiability: We have to find a latent-variable model which is identifiable, i.e. the parameters of the model can be uniquely estimated. Quite often, the identifiability of a latent-variable model then leads to identifiability of a causal discovery model. In this talk, I will review research on this connection and the relevant identifiability theory. </p></tr>


            <tr>- <a href="https://www.hsph.harvard.edu/miguel-hernan/">Miguel Hernan</a>, CAUSALab / Biostatistics and Epidemiology, Harvard T.H. Chan School </tr>
            <tr><b>Title:</b> Causal AI for data analysis in the health sciences </tr>
            <tr><p><b>Abstract:</b> The tools referred to as AI may assist, or replace, health researchers who learn from data. This talk describes a taxonomy of learning tasks in science and explores the relationship between two of them: prediction (pattern recognition) and counterfactual prediction (causal inference). Researchers predict counterfactually by using a combination of data and causal models of the world. In contrast, AI tools developed for prediction using only data are being increasingly used for counterfactual prediction. This raises questions about the origin of causal models, and the future of causal inference research in the health sciences. </p></tr>


            <tr>- <a href="https://people.epfl.ch/negar.kiyavash?lang=en">Negar Kiyavash</a>, École polytechnique fédérale de Lausanne </tr>
            <tr><b>Title:</b> Causal Identification: Are We There Yet?</tr>
            <tr><p><b>Abstract:</b> We discuss causal identifiability, the canonical problem of causal inference, where the goal is to calculate the effect of intervening on subset of variables on an outcome variable of interest. We first visit the definition fo the problem and note that it is necessary to add positivity assumption of observational distribution to the original definition of the problem as without such an assumption the rules of do-calculus and consequently the proposed algorithms in the field are not sound. After discussing state of the art and recent progress in the field, we present some of the open problems and remaining challenges.</p></tr>

            </table>
        </div>
          )
}

export default KeynoteSpeaker2023page