import React from "react";
import Title from "../../../components/Title/Title";
import './RegistrationPage.scss';

const Registration2024page = () => {
  return (
    <div>
      <Title>Venue and Registration</Title>
      <h5>Venue</h5>
      <p>CLeaR 2024 will be held at <a href="https://conferences.ucla.edu/meeting-event-spaces/carnesale-commons/">Carnesale Commons</a>, UC Los Angeles, California from April 1 to 3, 2024. We encourage participation in person, but the conference also includes virtual components. </p>
      <h5>Registration</h5>
      <p>You can register for CLeaR 2024 <a href="https://clear.regfox.com/clear-2024/">here</a>. Registration will be open until March 15, 2024. As we have a limited number of available tickets, please register as soon as possible. Please contact us if you need an invitation letter for a visa application.</p>
    </div>
  )
}

export default Registration2024page

