import Header from '../components/Header/Header'
import NavBar from '../components/NavBar/NavBar';
import Content from '../components/Content/Content';
import './App.scss';

function App() {
  return (
    <div className="App">
      <Header />
      <div className="container">
        <div className="row">
          <NavBar />
          <Content />
        </div>     
      </div>
    </div>
  );
}

export default App;
