import React from "react";
import Title from "../../../components/Title/Title";
import './SponsorsPage.scss';

const Sponsors2023page = () => {
  return (
    <div>
    <Title>Sponsors</Title>
  <center>
  <h5 className="SponsorPage__tableTile--gold">Gold Sponsors</h5>
  <table className="SponsorPage__table">
    <tr>
      <td><img src={require('../../../assets/AMAZON.png').default} width="200"/></td>
      {/* <td><img src={require('../../../assets/MIT-IBM-logo.png').default} width="110"/></td> */}
      <td><img src={require('../../../assets/microsoft.png').default} width="200"/></td>
    </tr>  
  </table>
  <h5 className="SponsorPage__tableTile--silver">Silver Sponsors</h5>
  <table className="SponsorPage__table">
    <tr>
      {/* <td><img src={require('../../../assets/Qualcomm.jpg').default} width="200"/></td> */}
      <td><img src={require('../../../assets/causalens.png').default} width="200"/></td>
    </tr>  
  </table>
  <h5 className="SponsorPage__tableTile--bronze">Bronze Sponsors</h5>
  <table className="SponsorPage__table">
    <tr>
    <td><img src={require('../../../assets/googlelogo_color_272x92dp.png').default} width="200"/></td>
    </tr>  
  </table>
  </center>
</div>
  )
}

export default Sponsors2023page
